import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85)), url('/images/neukundengewinnung/info.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0 180px',
        widescreen: '80px 0 180px',
        desktop: '80px 40px 180px',
        tablet: '60px 40px 200px',
        mobile: '40px 20px 200px'
      },
      containers: [
        {
          type: 'text',
          config: {
            defaultCss: {
              color: '#fff'
            },
            margin: {
              fullHd: '0',
              widescreen: '0 140px',
              desktop: '0 140px',
              tablet: '0 40px 20px',
              mobile: '0 0 20px'
            },
            text: 'Wie viel Kundenanfragen sind für Dich im Monat möglich?',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 40px',
              tablet: '30px auto 40px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              textAlign: 'center',
              color: '#fff'
            },
            margin: {
              fullHd: '0 120px 20px',
              widescreen: '0 20px 20px',
              desktop: '0 100px 20px',
              tablet: '0 0 20px',
              mobile: '0 0 20px'
            },
            text:
              'Mit der leeeds-Methode sorgen wir dafür, dass Dir <b>jeden Monat</b> 50 – 70 hochwertige Kundenanfragen zur Verfügung stehen, von denen unsere Klienten in der Regel 10 – 15 abschließen.'
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              textAlign: 'center',
              color: '#fff'
            },
            text: '<b>Werde jetzt unsere nächste Erfolgsgeschichte.</b>'
          }
        },
        {
          type: 'cta',
          config: {
            dark: true,
            ...ctaButton,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
