import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'
import ctaButton from './ctaButton'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  },
  margin: {
    fullHd: '0 130px 20px',
    widescreen: '0 60px 20px',
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          defaultCss: {
            margin: '-200px 0 0 0'
          },
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              defaultCss: {
                background: '#fff',
                boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
              },
              padding: {
                fullHd: '80px 80px 60px',
                widescreen: '80px 80px 60px',
                desktop: '80px 40px 60px',
                tablet: '60px 40px 40px',
                mobile: '40px 20px 20px'
              },
              margin: {
                desktop: '0 0 60px',
                tablet: '0 0 60px',
                mobile: '0 0 30px'
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      color: colors.title
                    },
                    margin: {
                      fullHd: '0 80px 10px',
                      widescreen: '0 0 10px',
                      desktop: '0 0 10px',
                      tablet: '0 10px 10px',
                      mobile: '0 10px 10px'
                    },
                    size: 2,
                    text:
                      'Durch einen vollautomatisierten Akquise-Prozess erhalten Sie Anfragen rund um die Uhr'
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      fontWeight: '400'
                    },
                    size: 4,
                    text: '– selbst dann, wenn Sie nicht arbeiten'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 40px',
                      tablet: '30px auto 40px',
                      mobile: '30px auto 40px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b>Stellen Sie sich Folgendes vor</b>: Sie sitzen abends auf der Couch und lassen den Tag ausklingen. Einfach mal vom Alltagsstress abschalten und die Zeit mit der Familie genießen…'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Um Ihren beruflichen Erfolg brauchen Sie sich <u>keine Gedanken zu machen</u>, denn... '
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Genau in dieser Sekunde kommen <b>neue Anfragen</b> rein. Anfragen von Immobilienbesitzern, die abends vor Ihrem Computer sitzen und eine Möglichkeit suchen, ihr Haus oder ihre Wohnung zu verkaufen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Ihr Akquise-Prozess schläft nie und läuft <b>24 Stunden - Tag und Nacht</b>.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Und morgens, wenn Sie wieder im Büro sind, ist Ihr Postfach <b>voller Anfragen</b> von Interessenten, die bei Ihnen anfragen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Sie können den Tag erfolgreich starten – mit der Gewissheit, jede Menge <b>neue Aufträge</b> zu haben!'
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop pl-0 pr-0',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center',
                      color: colors.title
                    },
                    margin: {
                      desktop: '0',
                      tablet: '0 40px 20px',
                      mobile: '0 10px 20px'
                    },
                    size: 3,
                    text:
                      'Wollen Sie auch vollautomatisiert neue Immobilien-Aufträge gewinnen?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center'
                    },
                    margin: {
                      desktop: '0 0 20px',
                      tablet: '0 140px 20px',
                      mobile: '0 0 20px'
                    },
                    text:
                      'Dann klicken Sie jetzt auf den Button und erfahren SIe, wie das auch für Sie möglich ist!'
                  }
                },
                {
                  type: 'cta',
                  config: {
                    dark: false,
                    ...ctaButton,
                    margin: {
                      desktop: '50px 0 0',
                      tablet: '50px 0 0',
                      mobile: '30px 0 0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
