import { ContainerBox } from '@peakconcepts/landingpage-kit'

// RECRUITING Data
import recruitingHeroData from '../../content/data/recruiting/hero'
import recruitingMistakeData from '../../content/data/recruiting/mistake'
import recruitingWrongPlaceData from '../../content/data/recruiting/wrongPlace'
// import recruitingSolutionData from '../../content/data/recruiting/solution'
import recruitingFormulaData from '../../content/data/recruiting/formula'
import recruitingCostsData from '../../content/data/recruiting/costs'
import recruitingAdvantagesData from '../data/recruiting/advantages'
import recruitingResultsData from '../data/recruiting/results'
import recruitingAboutData from '../../content/data/recruiting/about'
import recruitingContactData from '../../content/data/recruiting/contact'
import recruitingProcessData from '../../content/data/recruiting/process'

// Shared
import knownFrom from '../../content/data/shared/knownfrom'

const data = [
  {
    component: ContainerBox,
    data: {
      ...recruitingHeroData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...knownFrom
    }
  },
  {
    component: ContainerBox,
    data: {
      ...recruitingMistakeData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...recruitingWrongPlaceData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...recruitingContactData
    }
  },
  /*{
    component: ContainerBox,
    data: {
      ...recruitingSolutionData
    }
  },*/
  {
    component: ContainerBox,
    data: {
      ...recruitingFormulaData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...recruitingAdvantagesData
    }
  },
  /*{
    component: ContainerBox,
    data: {
      ...recruitingResultsData
    }
  },*/
  {
    component: ContainerBox,
    data: {
      ...recruitingCostsData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...recruitingAboutData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...recruitingProcessData
    }
  }
]

export default data
