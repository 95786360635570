import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../default/colors'

const itemNumberBoxClassNames = 'column is-2-mobile is-1-tablet is-1-desktop'
const itemTextBoxClassNames = 'column is-10-mobile is-11-tablet is-11-desktop'

const itemNumberBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    media: [
      {
        breakpoint: 'mobile',
        css: { justifyContent: 'flex-start' }
      }
    ]
  }
}

const itemContainerConfig = {
  defaultCss: {
    margin: '0 0 10px !important',
    media: [
      {
        breakpoint: 'tablet',
        css: { margin: '0 !important' }
      }
    ]
  }
}

const itemNumberConfig = {
  size: 4,
  fontSize: {
    fullHd: '80px',
    widescreen: '80px',
    desktop: '60px',
    tablet: '60px',
    mobile: '60px'
  },
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  },
  defaultCss: {
    color: 'rgba(255, 170, 0, 0.75)',
    lineHeight: '1',
    textAlign: 'left'
  }
}
const itemDescriptionConfig = {
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85)), url('/images/shapes/textur.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames:
            'columns is-multiline is-mobile is-centered is-marginless',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-10-widescreen',
              padding: {
                desktop: '40px',
                tablet: '40px',
                mobile: '16px 0'
              },
              defaultCss: {
                backgroundColor: '#fff',
                boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
                media: [
                  {
                    breakpoint: 'mobile',
                    css: { background: 'none', boxShadow: 'none' }
                  }
                ]
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    margin: 0,
                    size: 2,
                    text: 'Du hast 2 Optionen:',
                    defaultCss: {
                      textAlign: 'center',
                      color: colors.title
                    }
                  }
                },
                {
                  type: 'hr',
                  config: {
                    defaultCss: {
                      color: 'primary',
                      marginTop: '30px'
                    },
                    height: 4
                  }
                },
                {
                  classNames: 'columns is-multiline is-mobile',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemNumberBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemNumberConfig,
                            text: '1'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Du machst Dich selbst auf die Reise und investierst <u>viel Zeit und Geld</u> in Methoden, von denen Du nicht zu 100 % weißt, ob sie <u>überhaupt</u> funktionieren.'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'columns is-multiline is-mobile',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemNumberBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemNumberConfig,
                            text: '2'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Oder Du beauftragst einen Experten und gehst den <u>sicheren Weg</u>. Profitiere durch die Erfahrung von Profis, die für Dich <u>Best-Ergebnisse</u> erzielen, während Du Dich <u>voll & ganz</u> auf Deine Kunden konzentrieren kannst.'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'text',
                  config: {
                    margin: '30px 0 35px',
                    text:
                      '➞ Investiere Deine Zeit sinnvoll und <b>fokussiere Dich auf Deine Stärken</b>, während Du die Neukundengewinnung von einem Profi für Dich umsetzen lässt.'
                  }
                },
                {
                  type: 'cta',
                  config: {
                    dark: false,
                    margin: {
                      desktop: '10px 0',
                      tablet: '10px 0 0',
                      mobile: '10px 0 0'
                    },
                    ...ctaButton
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
