import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../default/colors'
import ProvenExpert from '../../../components/ProvenExpert'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.90)), url('/images/neukundengewinnung/hero.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      type: 'component',
      component: ProvenExpert
    },
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '60px 0 40px',
            widescreen: '60px 0 40px',
            desktop: '60px 40px 40px',
            tablet: '80px 40px 60px',
            mobile: '70px 20px 20px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 6,
                        fontSize: {
                          desktop: '16px',
                          tablet: '14px',
                          mobile: '14px'
                        },
                        margin: {
                          desktop: '0 0 10px',
                          tablet: '0 0 10px',
                          mobile: '0 0 20px'
                        },
                        text:
                          'Als Coach, Experte, Trainer oder Dienstleister <u>professionell</u> skalieren'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        margin: {
                          desktop: '0 50px 20px',
                          tablet: '0 0 20px',
                          mobile: '0 0 20px'
                        },
                        defaultCss: {
                          color: colors.title,
                          media: [
                            {
                              breakpoint: 'tablet',
                              css: { lineHeight: '1.2' }
                            }
                          ]
                        },
                        text:
                          'Automatisiere Deine Neukundengewinnung und erhalte jeden Tag 2-3 Anfragen von Interessenten, die auf Dich zukommen'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        margin: {
                          desktop: '0 210px 20px',
                          tablet: '0',
                          mobile: '0'
                        },
                        defaultCss: {
                          textAlign: 'center'
                        },
                        text:
                          'Systematisiere Deinen Vertrieb und gewinne Kundenanfragen wie am Fließband, um <b>mehr Zeit für Dein Kerngeschäft</b> zu haben!'
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'video',
                      config: {
                        url: 'https://vimeo.com/540604746',
                        playIconColor: '#004567',
                        disableLazyLoading: true
                      }
                    },
                    {
                      containers: [
                        {
                          classNames: 'columns is-multiline',
                          containers: [
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  type: 'text',
                                  config: {
                                    text: 'Plattformen zur Neukundengewinnung:',
                                    fontSize: {
                                      desktop: '14px',
                                      tablet: '14px',
                                      mobile: '12px'
                                    },
                                    margin: {
                                      desktop: '20px 0 0',
                                      tablet: '20px 0 0',
                                      mobile: '20px 0 0'
                                    },
                                    defaultCss: {
                                      media: [
                                        {
                                          breakpoint: 'desktop',
                                          css: {
                                            textAlign: 'center'
                                          }
                                        }
                                      ]
                                    }
                                  }
                                }
                              ]
                            },
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  classNames:
                                    'columns is-multiline is-mobile is-marginless',
                                  containers: [
                                    {
                                      classNames: 'column is-12 pt-0 pl-0 pr-0',
                                      containers: [
                                        {
                                          type: 'image',
                                          config: {
                                            src: '/images/badges/badges.png',
                                            width: {
                                              desktop: '100%',
                                              tablet: '100%',
                                              mobile: '100%'
                                            }
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Starkes Umsatzwachstum</b>, da konstant neue Kunden bei Dir anfragen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Mehr Zeit</b> für Deine Kunden, denn wir übernehmen die komplette Akquise für Dich',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Deine Abschlussquote steigt</b>, weil die Anfragen ein starkes Kaufinteresse haben',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Sicheres Einkommen</b>, weil Deine Neukundengewinnung planbar wird',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        classNames: 'is-hidden-mobile',
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '30px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
