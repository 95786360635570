import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../../content/default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    color: '#fff',
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85)), url('/images/immobilienmakler/beforeYouStart.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      color: '#fff'
                    },
                    text:
                      'Stopp! Bevor Sie weiterlesen, beantworten Sie diese 6 Fragen ehrlich für sich selbst, um herauszufinden, ob eine Zusammenarbeit mit uns Sinn macht:',
                    size: 2
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 24px',
                      tablet: '30px auto 24px',
                      mobile: '30px auto 24px'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-full',
              containers: [
                {
                  classNames: 'columns is-multiline',
                  containers: [
                    {
                      classNames: 'column is-12-mobile is-6-tablet',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Sind Sie auf der Suche nach einer Lösung, wie Sie <u>mit System</u> planbar neue Aufträge gewinnen, ohne auf Empfehlungen hoffen zu müssen?',
                              ...itemDescriptionConfig
                            },
                            iconConfig: {
                              ...itemIconConfig
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: 'column is-12-mobile is-6-tablet',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Ihre Konkurrenz gewinnt ihre Kunden schon längst über digitale Vertriebsprozesse und Sie wissen nicht, wie Sie aufholen können?',
                              ...itemDescriptionConfig
                            },
                            iconConfig: {
                              ...itemIconConfig
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: 'column is-12-mobile is-6-tablet',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Haben Sie noch <u>Kapazitäten frei</u> und möchten diese mit Aufträgen füllen?',
                              ...itemDescriptionConfig
                            },
                            iconConfig: {
                              ...itemIconConfig
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: 'column is-12-mobile is-6-tablet',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Wollen Sie Ihre Neukundengewinnung professionell und vor allem effizient gestalten?',
                              ...itemDescriptionConfig
                            },
                            iconConfig: {
                              ...itemIconConfig
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: 'column is-12-mobile is-6-tablet',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Verbringen Sie Ihre Zeit aktuell mit aufwendiger Kaltakquise und wünschen sich <u>mehr Fokus für Ihr Kerngeschäft</u> oder für Ihre Familie?',
                              ...itemDescriptionConfig
                            },
                            iconConfig: {
                              ...itemIconConfig
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: 'column is-12-mobile is-6-tablet',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Sie wünschen sich, dass <u>ideale Kunden auf Sie zukommen</u> und Sie neuen Interessenten nicht länger hinterherrennen müssen?',
                              ...itemDescriptionConfig
                            },
                            iconConfig: {
                              ...itemIconConfig
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      color: '#fff',
                      textAlign: 'center'
                    },
                    margin: {
                      fullHd: '0 320px',
                      widescreen: '0 220px',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    text:
                      'Dann sind Sie bei uns <u>genau richtig</u>, denn wir helfen Ihnen, Ihre <b>Neukundengewinnung zu systematisieren!</b>'
                  }
                },
                {
                  type: 'cta',
                  config: {
                    dark: true,
                    ...ctaButton,
                    margin: {
                      desktop: '50px 0 0',
                      tablet: '50px 0 0',
                      mobile: '30px 0 0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
