import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../../content/default/colors'
import ProvenExpert from '../../../components/ProvenExpert'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.9)), url('/images/recruiting/hero.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      type: 'component',
      component: ProvenExpert
    },
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '120px 0 80px',
            widescreen: '120px 0 80px',
            desktop: '120px 40px 80px',
            tablet: '80px 40px',
            mobile: '70px 20px 20px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        margin: {
                          fullHd: '0 20px 20px',
                          widescreen: '0 20px 20px',
                          desktop: '0 0 20px',
                          tablet: '0 0 20px',
                          mobile: '0 0 20px'
                        },
                        defaultCss: {
                          color: colors.title,
                          media: [
                            {
                              breakpoint: 'tablet',
                              css: { lineHeight: '1.2' }
                            }
                          ]
                        },
                        text:
                          'Wie Sie trotz Fachkräftemangel qualifizierte Mitarbeiter gewinnen und zur bekannten & attraktiven Arbeitgebermarke werden'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        size: 5,
                        margin: {
                          fullHd: '0 180px 20px',
                          widescreen: '0 180px 20px',
                          desktop: '0 20px 20px',
                          tablet: '0',
                          mobile: '0'
                        },
                        defaultCss: {
                          textAlign: 'center',
                          color: '#000'
                        },
                        text:
                          'So besetzen Sie offene Stellen <u>innerhalb kurzer Zeit</u> – ohne Headhunter, Personalvermittler oder Jobportale.'
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      containers: [
                        {
                          classNames: 'columns is-multiline',
                          defaultCss: {
                            textAlign: 'center'
                          },
                          containers: [
                            {
                              type: 'image',
                              config: {
                                src: '/images/recruiting/hero.svg',
                                width: {
                                  fullHd: '80%',
                                  widescreen: '80%',
                                  desktop: '80%',
                                  tablet: '100%',
                                  mobile: '100%'
                                },
                                margin: {
                                  fullHd: '0 auto',
                                  widescreen: '0 auto',
                                  desktop: '0 auto',
                                  tablet: '0 auto',
                                  mobile: '0 auto'
                                }
                              }
                            },
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  type: 'text',
                                  config: {
                                    text:
                                      'Plattformen zur Mitarbeitergewinnung:',
                                    fontSize: {
                                      desktop: '14px',
                                      tablet: '14px',
                                      mobile: '12px'
                                    },
                                    margin: {
                                      desktop: '20px 0 0',
                                      tablet: '20px 0 0',
                                      mobile: '20px 0 0'
                                    },
                                    defaultCss: {
                                      media: [
                                        {
                                          breakpoint: 'desktop',
                                          css: {
                                            textAlign: 'center'
                                          }
                                        }
                                      ]
                                    }
                                  }
                                }
                              ]
                            },
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  classNames:
                                    'columns is-multiline is-mobile is-marginless',
                                  containers: [
                                    {
                                      classNames: 'column is-12 pt-0 pl-0 pr-0',
                                      containers: [
                                        {
                                          type: 'image',
                                          config: {
                                            src: '/images/badges/badges.png',
                                            width: {
                                              desktop: '100%',
                                              tablet: '100%',
                                              mobile: '100%'
                                            }
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Finden Sie Mitarbeiter, die auch wirklich zu Ihrem Unternehmen passen:</b> Unser Prozess qualifiziert geeignete Kandidaten für Sie vor.',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Exklusive Bewerbungen:</b> Profitieren Sie von Bewerbern, die sich nur bei Ihnen bewerben und vorher nicht aktiv auf der Suche waren.',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>​Steigerung der Bekanntheit als Arbeitgeber:</b> Durch unsere Recruitingstrategie werden Sie gleichzeitig auch als guter Arbeitgeber in Ihrer Region bekannt.',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        classNames: 'is-hidden-mobile',
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '30px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
