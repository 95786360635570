import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'

const itemNumberBoxClassNames =
  'column is-2-mobile is-1-tablet is-1-desktop pt-0 pb-0'
const itemTextBoxClassNames =
  'column is-10-mobile is-11-tablet is-11-desktop pl-0 pb-5 pt-0'

const itemDotsContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center'
  }
}

const itemTextBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

const itemDescriptionConfig = {
  defaultCss: {}
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 60px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            defaultCss: {
              color: colors.title
            },
            margin: {
              fullHd: '0 220px 0',
              widescreen: '0 220px 0',
              desktop: '0',
              tablet: '0',
              mobile: '0'
            },
            text:
              'Unsere Vorgehensweise ist effektiv, denn wir wissen, worauf es wirklich ankommt'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },

        {
          padding: {
            fullHd: '30px 0 0 0',
            widescreen: '30px 0 0 0',
            desktop: '30px 0 0 0',
            tablet: '30px 0 0 0',
            mobile: '30px 0 0 0'
          },
          classNames: 'columns is-multiline is-mobile',
          containers: [
            {
              defaultCss: {
                display: 'flex'
              },
              containers: [
                {
                  classNames: itemNumberBoxClassNames,
                  ...itemDotsContainer,
                  containers: [
                    {
                      type: 'dots',
                      config: {
                        color: 'rgb(255,170,0)',
                        dotConfig: {
                          dotSize: 2
                        }
                      }
                    }
                  ]
                },
                {
                  classNames: itemTextBoxClassNames,
                  ...itemTextBoxContainer,
                  containers: [
                    {
                      type: 'text',
                      config: {
                        ...itemDescriptionConfig,
                        text:
                          'Mit unserer Methodik sprechen wir <u>potenzielle Kunden</u> in den sozialen Medien direkt an. Menschen, die an Deiner Dienstleistung <b>interessiert</b> sind…'
                      }
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '… diesen Personen präsentieren wir Dein Angebot so, dass sie diesem <u>nicht widerstehen können</u> und sich dann von selbst <b>bei Dir melden</b>, um sich zu informieren…'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            showLines: false,
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '… so schaffen wir Dir laufend einen <b>riesigen Pool</b> an brandneuen Interessenten, die eine Zusammenarbeit mit Dir anfragen.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          defaultCss: {
            borderImage:
              'linear-gradient(160deg, rgba(227, 237, 250, 1) 0%, rgba(227, 237, 250, 1) 24%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 75%, rgba(227, 237, 250, 1) 76%, rgba(227, 237, 250, 1) 100%)',
            borderWidth: '3px',
            borderImageSlice: '1',
            borderStyle: 'solid'
          },
          margin: {
            desktop: '0 20px',
            tablet: '0',
            mobile: '0'
          },
          padding: {
            desktop: '30px 40px',
            tablet: '30px',
            mobile: '30px'
          },
          containers: [
            {
              type: 'text',
              config: {
                size: 4,
                margin: 0,
                text: 'Wir übernehmen die komplette Anfragengewinnung für Dich!'
              }
            },
            {
              type: 'text',
              config: {
                defaultCss: {
                  textAlign: 'center'
                },
                margin: {
                  desktop: '0 180px 0',
                  tablet: '0',
                  mobile: '0'
                },
                text:
                  'Das Einzige, was Du noch machen musst, ist den Interessenten anrufen, abschließen und Dich über neue Aufträge freuen.'
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
