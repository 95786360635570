import { ContainerBox } from '@peakconcepts/landingpage-kit'

import heroData from '../data/neukundengewinnung/hero'
import openingData from '../data/neukundengewinnung/opening'
import optionsData from '../data/neukundengewinnung/options'
import mistakeData from '../data/neukundengewinnung/mistake'
import reviewsData from '../data/neukundengewinnung/reviews'
import gamechangerData from '../data/neukundengewinnung/gamechanger'
import infoData from '../data/neukundengewinnung/info'
import aroundTheClockData from '../data/neukundengewinnung/aroundTheClock'
import howData from '../data/neukundengewinnung/how'
import advantagesData from '../data/neukundengewinnung/advantages'
import aboutData from '../data/neukundengewinnung/about'
import resultsData from '../data/neukundengewinnung/results'
import processData from '../data/neukundengewinnung/process'
import contactsData from '../data/neukundengewinnung/contact'
import oldwayNewwayData from '../data/neukundengewinnung/oldwayNewway'
import closingData from '../data/neukundengewinnung/closing'
import faqsData from '../data/neukundengewinnung/faqs'

// Shared
import knownFrom from '../data/shared/knownfrom'

const data = [
  {
    component: ContainerBox,
    data: {
      ...heroData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...knownFrom
    }
  },
  {
    component: ContainerBox,
    data: {
      ...openingData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...optionsData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...mistakeData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...gamechangerData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...reviewsData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...infoData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...aroundTheClockData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...oldwayNewwayData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...howData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...advantagesData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...aboutData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...resultsData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...processData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...contactsData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...closingData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...faqsData
    }
  }
]

export default data
