const data = {
  text: 'Jetzt Potenzial-Analyse vereinbaren',
  subline: 'Hier klicken, um Termin zu vereinbaren',
  link: 'Kostenfrei & unverbindlich',
  url: {
    to: '/recruiting/termin-buchen',
    internalLink: true
  }
}

export default data
