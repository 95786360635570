import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemTitleConfig = {
  size: 2,
  defaultCss: {
    textAlign: 'left',
    color: colors.title,
    media: [
      {
        breakpoint: 'tablet',
        css: {
          textAlign: 'center'
        }
      }
    ]
  },
  margin: {
    desktop: '0 0 10px 0',
    tablet: '0 0 10px 0',
    mobile: '0 0 10px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'about'
  },
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      defaultCss: {
        backgroundImage:
          "linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85)), url('/images/recruiting/about.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0 180px',
            widescreen: '80px 0 180px',
            desktop: '80px 40px 180px',
            tablet: '60px 40px 200px',
            mobile: '40px 20px 200px'
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              color: '#fff'
            },
            margin: {
              fullHd: '0 400px',
              widescreen: '0 150px',
              desktop: '0 40px',
              tablet: '0 20px',
              mobile: '0 20px'
            },
            padding: {
              fullHd: '80px 0 180px',
              widescreen: '80px 0 180px',
              desktop: '80px 40px 180px',
              tablet: '60px 40px 200px',
              mobile: '40px 20px 200px'
            },
            text:
              'Mehr Sicherheit, durch die Zusammenarbeit mit einem erfahrenen Partner',
            size: 2
          }
        }
      ]
    },
    {
      defaultCss: {
        margin: '-200px 0 0 0'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px'
          },
          containers: [
            {
              defaultCss: {
                background: '#fff',
                boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
              },
              padding: {
                fullHd: '80px 80px 60px',
                widescreen: '80px 80px 60px',
                desktop: '80px 40px 60px',
                tablet: '60px 40px 40px',
                mobile: '40px 20px 20px'
              },
              containers: [
                {
                  classNames: 'columns is-multiline',
                  containers: [
                    {
                      classNames: 'column is-full',
                      containers: [
                        {
                          type: 'text',
                          config: {
                            defaultCss: {
                              media: [
                                {
                                  breakpoint: 'tablet',
                                  css: {
                                    textAlign: 'center',
                                    textDecoration: 'underline'
                                  }
                                }
                              ]
                            },
                            margin: {
                              desktop: '0',
                              tablet: '0 60px 20px',
                              mobile: '0 0 20px'
                            },
                            text:
                              'Mehr Sicherheit, durch die Zusammenarbeit mit einem erfahrenen Partner'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'leeeds.de - eine Marke der Peakconcepts GmbH'
                          }
                        }
                      ]
                    },
                    {
                      classNames:
                        'column is-12-mobile is-12-tablet is-5-desktop',
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/melik-su.jpg',
                            width: '100%'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            fontSize: {
                              desktop: '12px',
                              tablet: '12px',
                              mobile: '12px'
                            },
                            text:
                              '<b>Melik Su</b> - Geschäftsführer Peakconcepts GmbH '
                          }
                        }
                      ]
                    },
                    {
                      classNames:
                        'column is-12-mobile is-12-tablet is-7-desktop',
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '<b>leeeds.de</b> steht für jahrelange Erfahrung in der Mitarbeitergewinnung. Wir kennen den aktuellen Arbeitsmarkt und wissen, wie schwer es sein kann, den richtigen Mitarbeiter zu finden.'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Unsere Methode ist praxiserprobt und bringt nachweislich Erfolge: Sie findet sowohl bei unseren Kunden als auch intern bei uns Anwendung. Damit Sie einen Mitarbeiter finden, der langfristig an Ihr Unternehmen gebunden ist, analysieren wir Ihre Situation bis ins kleinste Detail und entwerfen daraufhin eine perfekte Lösung für Ihre persönlichen Anforderungen. '
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
