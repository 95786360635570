import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../../content/default/colors'
import ProvenExpert from '../../../components/ProvenExpert'

const itemTitleDescriptionConfig = {
  defaultCss: {
    textAlign: 'center',
    color: '#000'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left',
    color: '#000'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 7,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.9)), url('/images/immobilienmakler/hero.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'bottom center'
  },
  containers: [
    {
      type: 'component',
      component: ProvenExpert
    },
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '60px 0 40px',
            widescreen: '60px 0 40px',
            desktop: '60px 40px 40px',
            tablet: '80px 40px 60px',
            mobile: '70px 20px 20px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 2,
                        margin: {
                          desktop: '30px 50px 20px',
                          tablet: '0 0 20px',
                          mobile: '0 0 20px'
                        },
                        defaultCss: {
                          color: colors.title,
                          media: [
                            {
                              breakpoint: 'tablet',
                              css: { lineHeight: '1.2' }
                            }
                          ]
                        },
                        text:
                          'Als Immobilienmakler jeden Monat 5-8 exklusive Anfragen von Hausbesitzern erhalten, die ihr Objekt verkaufen wollen'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        margin: {
                          desktop: '0 180px 20px',
                          tablet: '0',
                          mobile: '0'
                        },
                        defaultCss: {
                          textAlign: 'center',
                          color: '#000'
                        },
                        text:
                          'Wir übernehmen die <b>komplette Gewinnung</b> von neuen Anfragen für Sie. Sie müssen nur noch anrufen, Termine vereinbaren und sich <b>über neue Aufträge freuen</b>!'
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'video',
                      config: {
                        url: 'https://vimeo.com/565961691',
                        playIconColor: '#004567',
                        previewUrl: '/videos/immobilienmakler-preview.mp4',
                        disableLazyLoading: true
                      }
                    },
                    {
                      containers: [
                        {
                          classNames: 'columns is-multiline',
                          containers: [
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  type: 'text',
                                  config: {
                                    text: 'Plattformen zur Neukundengewinnung:',
                                    fontSize: {
                                      desktop: '14px',
                                      tablet: '14px',
                                      mobile: '12px'
                                    },
                                    margin: {
                                      desktop: '20px 0 0',
                                      tablet: '20px 0 0',
                                      mobile: '20px 0 0'
                                    },
                                    defaultCss: {
                                      media: [
                                        {
                                          breakpoint: 'desktop',
                                          css: {
                                            textAlign: 'center'
                                          }
                                        }
                                      ]
                                    }
                                  }
                                }
                              ]
                            },
                            {
                              classNames: 'column is-full pb-0',
                              containers: [
                                {
                                  classNames:
                                    'columns is-multiline is-mobile is-marginless',
                                  containers: [
                                    {
                                      classNames: 'column is-12 pt-0 pl-0 pr-0',
                                      containers: [
                                        {
                                          type: 'image',
                                          config: {
                                            src: '/images/badges/badges.png',
                                            width: {
                                              desktop: '100%',
                                              tablet: '100%',
                                              mobile: '100%'
                                            }
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text:
                          'Machen Sie sich endlich <b>unabhängig</b> von Kaltakquise, Empfehlungen und überteuerten Immobilienportalen & erhalten Sie <b>jeden Monat</b> einen soliden Strom an qualifizierten Kundenanfragen, <b><u>auf die nur Sie Zugriff haben</u></b>.',
                        ...itemTitleDescriptionConfig
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Bessere Gespräche, weil die Interessenten ein starkes Interesse an einer Zusammenarbeit mit Ihnen haben',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Eine höhere Abschlussquote & mehr Umsatz',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Mehr Sicherheit, weil die Gewinnung von neuen Aufträgen planbar wird',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        classNames: 'is-hidden-mobile',
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '30px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
