import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      'linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85))',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    defaultCss: {
                      color: '#fff'
                    },
                    text:
                      'Sind Sie bereit, endlich die richtigen Mitarbeiter zu gewinnen?'
                  }
                },

                {
                  type: 'cta',
                  config: {
                    dark: true,
                    ...ctaButton,
                    margin: {
                      desktop: '50px 0 0',
                      tablet: '50px 0 0',
                      mobile: '30px 0 0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
