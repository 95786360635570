import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const titleConfig = {
  size: 5,
  defaultCss: {
    textAlign: 'center',
    color: '#fff'
  },
  fontSize: {
    desktop: '22px',
    tablet: '18px',
    mobile: '18px'
  },
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  }
}

const descriptionConfig = {
  defaultCss: {
    textAlign: 'center',
    color: '#fff'
  },
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  }
}

const itemIconConfig = {
  size: 13,
  defaultCss: {
    color: 'primary'
  },
  margin: {
    desktop: '0 auto 20px',
    tablet: '0 auto 20px',
    mobile: '0 auto 20px'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'vorteile'
  },
  defaultCss: {
    backgroundImage:
      'linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85))',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    defaultCss: {
                      color: '#fff'
                    },
                    text: 'Ihre Vorteile auf einen Blick'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 30px',
                      tablet: '30px auto 20px',
                      mobile: '30px auto 20px'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  containers: [
                    {
                      classNames: 'column is-full p-0',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            iconConfig: {
                              icon: 'thumb_up',
                              ...itemIconConfig
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Steigerung Ihrer Bekanntheit',
                            ...titleConfig
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...descriptionConfig,
                            text:
                              'Steigern Sie Ihre Bekanntheit in den sozialen Netzwerken und im Internet und werden Sie sichtbar für die richtigen Fachkräfte.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  containers: [
                    {
                      classNames: 'column is-full p-0',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            iconConfig: {
                              icon: 'group_add',
                              ...itemIconConfig
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Mehr Bewerbungen',
                            ...titleConfig
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...descriptionConfig,
                            text:
                              'Erhalten Sie mehr Bewerbungen von qualifizierten Fachkräften, um offene Stellen mit passenden Bewerbern in kurzer Zeit zu besetzen.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  containers: [
                    {
                      classNames: 'column is-full p-0',
                      containers: [
                        {
                          type: 'textWithIcon',
                          config: {
                            iconConfig: {
                              icon: 'trending_up',
                              ...itemIconConfig
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            text: 'Aufbau der Arbeitgebermarke',
                            ...titleConfig
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...descriptionConfig,
                            text:
                              'Durch ein Employer Branding Konzept bauen Sie sich ein attraktives Image auf in den sozialen Netzwerken und im Internet.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          type: 'cta',
          config: {
            dark: true,
            ...ctaButton,
            margin: {
              desktop: '30px 0 0',
              tablet: '30px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
