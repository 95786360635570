import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../../content/default/colors'

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(246, 246, 246, 0.8), rgba(246, 246, 246, 0.8)), url('/images/shapes/textur.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '0 0 80px',
            widescreen: '0 0 80px',
            desktop: '0 40px 80px',
            tablet: '0 40px 60px',
            mobile: '0 20px 40px'
          },
          containers: [
            {
              type: 'text',
              config: {
                defaultCss: {
                  color: colors.title
                },
                text: 'Wir arbeiten mit Gebietsschutz',
                size: 2
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 40px',
                  tablet: '30px auto 40px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              type: 'text',
              config: {
                defaultCss: {
                  textAlign: 'center'
                },
                margin: {
                  desktop: '0 120px 20px',
                  tablet: '0 0 20px',
                  mobile: '0 0 20px'
                },
                text:
                  'Mit unserer Methode heben Sie sich von Ihren Mitbewerbern ab, denn Sie stehen nicht länger im Konkurrenzkampf mit anderen Maklern. Um Ihnen das zu gewährleisten, arbeiten wir nur mit einem Klienten pro Gebiet. Wenn Sie Ihre Region gebucht haben, dann sind Sie der Einzige, für den wir dort Kundenanfragen generieren.'
              }
            },
            {
              type: 'text',
              config: {
                size: 4,
                defaultCss: {
                  textAlign: 'center'
                },
                text: 'Sie wollen wissen, ob Ihre Region noch verfügbar ist?'
              }
            },
            {
              type: 'text',
              config: {
                defaultCss: {
                  textAlign: 'center'
                },
                text:
                  'Dann melden Sie sich jetzt zu einem unverbindlichen und völlig kostenlosen Erstgespräch bei uns.'
              }
            },
            {
              type: 'cta',
              config: {
                dark: false,
                ...ctaButton,
                margin: {
                  desktop: '50px 0 0',
                  tablet: '50px 0 0',
                  mobile: '30px 0 0'
                }
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
