import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../../content/default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          defaultCss: {
            boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
            position: 'relative',
            backgroundColor: '#fff',
            media: [
              {
                breakpoint: 'mobile',
                css: { background: 'none', boxShadow: 'none' }
              }
            ]
          },
          padding: {
            fullHd: '50px 100px',
            widescreen: '50px 100px',
            desktop: '50px 50px',
            tablet: '40px',
            mobile: '16px 0'
          },
          containers: [
            {
              type: 'text',
              config: {
                defaultCss: {
                  color: colors.title
                },
                text:
                  'Immobilienmakler, so wie wir sie heute kennen, wird es in einigen Jahren nicht mehr geben',
                size: 2
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 40px',
                  tablet: '30px auto 40px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  'Die meisten Immobilienmakler vertrauen auf veraltete Methoden wie Empfehlungsmarketing oder große Leadportale. Diese Methoden haben auch jahrelang super funktioniert, doch sie werden schleichend abgelöst. Denn die Gesellschaft - und vor allem das Kaufverhalten, <u>verändert sich durch die Digitalisierung immer mehr</u>.'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  '<b>Wer nicht mit der Zeit geht, wird mit der Zeit gehen:</b>'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  'Es wird immer schwieriger auf alte Methoden zu bauen und immer effektiver den Spieß umzudrehen und völlig neu an die Kundengewinnung heranzugehen.'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  '<b><u>Die Zahlen sprechen für sich</u></b>: Diejenigen, die ihren Akquise-Prozess bereits digitalisiert haben, gewinnen stetig an Marktanteilen, während die Konkurrenz stetig an Umsatz verliert.'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  '<b>Nur 2 % der Immobilienmakler nutzen Online-Marketing.</b>'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  '<u>Und genau das ist Ihre Chance</u>: Denn die Immobilienbranche steckt im Gegensatz zu den meisten Branchen immer noch in den Kinderschuhen. Die Karten werden JETZT neu gemischt. Wer sich jetzt richtig positioniert, hat in den nächsten Jahrzehnten einen entscheidenden Vorsprung gegenüber allen anderen.'
              }
            },
            {
              type: 'cta',
              config: {
                dark: false,
                ...ctaButton,
                margin: {
                  desktop: '50px 0 0',
                  tablet: '50px 0 0',
                  mobile: '30px 0 0'
                }
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
