import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'
import ctaButton from './ctaButton'

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '40px 0 0',
    tablet: '40px 0 0',
    mobile: '40px 0 0'
  }
}

const itemTitleConfig = {
  size: 4,
  defaultCss: {},
  margin: {
    desktop: '20px 0',
    tablet: '20px 0',
    mobile: '20px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemIconContainer = {
  defaultCss: {
    position: 'absolute',
    width: '100%',
    top: '-40px',
    left: '0',
    textAlign: 'center'
  }
}

const itemIconConfig = {
  size: 8,
  margin: {
    desktop: '0 auto'
  },
  defaultCss: {
    color: '#031234',
    margin: 'auto',
    width: '80px',
    height: '80px',
    padding: '20px',
    borderRadius: '50%',
    background: colors.primary
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(246, 246, 246, 0.8), rgba(246, 246, 246, 0.8)), url('/images/shapes/textur.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px'
          },
          containers: [
            {
              type: 'text',
              config: {
                size: 2,
                defaultCss: {
                  color: colors.title
                },
                text: 'So läuft eine Zusammenarbeit ab'
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 50px',
                  tablet: '30px auto 50px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-4-desktop is-flex',
                  containers: [
                    {
                      ...itemCardConfig,
                      containers: [
                        {
                          containers: [
                            {
                              ...itemIconContainer,
                              containers: [
                                {
                                  type: 'textWithIcon',
                                  config: {
                                    iconConfig: {
                                      icon: 'date_range',
                                      ...itemIconConfig
                                    }
                                  }
                                }
                              ]
                            },
                            {
                              type: 'text',
                              config: {
                                ...itemTitleConfig,
                                text: '1. Termin sichern'
                              }
                            },
                            {
                              type: 'text',
                              config: {
                                ...itemDescriptionConfig,
                                text:
                                  'Vereinbaren Sie jetzt eine kostenlose Potenzial-Analyse mit einem Experten aus dem Team. Buchen Sie hierzu über den Terminkalender ein freies Zeitfenster.'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-4-desktop is-flex',
                  containers: [
                    {
                      ...itemCardConfig,
                      containers: [
                        {
                          containers: [
                            {
                              ...itemIconContainer,
                              containers: [
                                {
                                  type: 'textWithIcon',
                                  config: {
                                    iconConfig: {
                                      icon: 'call',
                                      ...itemIconConfig
                                    }
                                  }
                                }
                              ]
                            },
                            {
                              type: 'text',
                              config: {
                                ...itemTitleConfig,
                                text: '2. Potenzial-Analyse'
                              }
                            },
                            {
                              type: 'text',
                              config: {
                                ...itemDescriptionConfig,
                                text:
                                  'Gemeinsam analysieren wir Ihre aktuelle Situation und beantworten alle Ihre Fragen zur Zusammenarbeit. Sie erhalten wertvolle Tipps, die Sie direkt umsetzen können.'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-4-desktop is-flex',
                  containers: [
                    {
                      ...itemCardConfig,
                      containers: [
                        {
                          containers: [
                            {
                              ...itemIconContainer,
                              containers: [
                                {
                                  type: 'textWithIcon',
                                  config: {
                                    iconConfig: {
                                      icon: 'trending_up',
                                      ...itemIconConfig
                                    }
                                  }
                                }
                              ]
                            },
                            {
                              type: 'text',
                              config: {
                                ...itemTitleConfig,
                                text: '3. Recruitingstrategie'
                              }
                            },
                            {
                              type: 'text',
                              config: {
                                ...itemDescriptionConfig,
                                text:
                                  'Wir erstellen gemeinsam eine individuelle und auf Ihre Bedürfnisse zugeschnittene Recruitingstrategie und setzen diese für Sie vollständig um.'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'column is-12',
                  containers: [
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '30px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
