import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../../content/default/colors'

const itemNumberBoxClassNames = 'column is-2-mobile is-1-tablet is-1-desktop'
const itemTextBoxClassNames = 'column is-10-mobile is-11-tablet is-11-desktop'

const itemNumberBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    media: [
      {
        breakpoint: 'mobile',
        css: { justifyContent: 'flex-start' }
      }
    ]
  }
}

const itemContainerConfig = {
  defaultCss: {
    margin: '0 0 10px !important',
    media: [
      {
        breakpoint: 'tablet',
        css: { margin: '0 !important' }
      }
    ]
  }
}

const itemNumberConfig = {
  size: 4,
  fontSize: {
    fullHd: '80px',
    widescreen: '80px',
    desktop: '60px',
    tablet: '60px',
    mobile: '60px'
  },
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  },
  defaultCss: {
    color: 'rgba(255, 170, 0, 0.75)',
    lineHeight: '1',
    textAlign: 'left'
  }
}
const itemDescriptionConfig = {
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  dark: {
    desktop: false,
    tablet: true,
    mobile: true
  },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(246, 246, 246, 0.8), rgba(246, 246, 246, 0.8)), url('/images/shapes/textur.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px'
          },
          containers: [
            {
              classNames:
                'columns is-multiline is-mobile is-centered is-marginless',
              containers: [
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-10-widescreen',
                  padding: {
                    desktop: '40px',
                    tablet: '40px',
                    mobile: '16px 0'
                  },
                  defaultCss: {
                    backgroundColor: '#fff',
                    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
                    media: [
                      {
                        breakpoint: 'mobile',
                        css: { background: 'none', boxShadow: 'none' }
                      }
                    ]
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        margin: 0,
                        size: 2,
                        text: 'Sie haben 2 Optionen:',
                        defaultCss: {
                          textAlign: 'center',
                          color: colors.title
                        }
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '120px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '30px auto 40px',
                          tablet: '30px auto 40px',
                          mobile: '30px auto 40px'
                        }
                      }
                    },
                    {
                      classNames: 'columns is-multiline is-mobile',
                      ...itemContainerConfig,
                      containers: [
                        {
                          classNames: itemNumberBoxClassNames,
                          ...itemNumberBoxContainer,
                          containers: [
                            {
                              type: 'text',
                              config: {
                                ...itemNumberConfig,
                                text: '1'
                              }
                            }
                          ]
                        },
                        {
                          classNames: itemTextBoxClassNames,
                          containers: [
                            {
                              type: 'text',
                              config: {
                                ...itemDescriptionConfig,
                                text:
                                  'Sie machen weiter wie bisher und verlieren nach und nach Marktanteile, weil Ihre Konkurrenz sich Online platziert und durch mehr Sichtbarkeit Ihnen die Kunden vor der Nase wegschnappt.'
                              }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      classNames: 'columns is-multiline is-mobile',
                      ...itemContainerConfig,
                      containers: [
                        {
                          classNames: itemNumberBoxClassNames,
                          ...itemNumberBoxContainer,
                          containers: [
                            {
                              type: 'text',
                              config: {
                                ...itemNumberConfig,
                                text: '2'
                              }
                            }
                          ]
                        },
                        {
                          classNames: itemTextBoxClassNames,
                          containers: [
                            {
                              type: 'text',
                              config: {
                                ...itemDescriptionConfig,
                                text:
                                  'Oder Sie beauftragen Experten und heben sich von der Masse ab. Erhalten Sie eigene exklusive Objektanfragen und genießen Sie völlige Unabhängigkeit!'
                              }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        margin: '30px 0 35px',
                        text:
                          'Wollen Sie exklusive Anfragen von Objekt-Verkäufern gewinnen und sich unabhängig von Leadportalen machen?'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Dann klicken Sie jetzt auf den Button und buchen Sie ein kostenloses Erstgespräch mit einem Experten. In einem 15-Minuten-Erstgespräch finden wir gemeinsam heraus, ob und wie wir Ihnen helfen können.'
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
