import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 0 20px 50px',
    widescreen: '0 0 20px 0',
    desktop: '0 0 20px 0',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px 60px',
        tablet: '60px 40px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    defaultCss: {
                      color: colors.title
                    },
                    margin: {
                      fullHd: '0 100px 40px',
                      widescreen: '0 0 40px',
                      desktop: '0 0 40px',
                      tablet: '0 0 40px',
                      mobile: '0 0 10px'
                    },
                    text:
                      'Sie sind Immobilienmakler und wollen jeden Monat <u>automatisiert und planbar</u> neue Anfragen erhalten?'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 40px',
                      tablet: '30px auto 40px',
                      mobile: '30px auto 20px'
                    }
                  }
                },
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/immobilienmakler/opening.jpg',
                    width: '100%',
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Sie fragen sich <u>jeden Monat</u>, wie Sie an <b>neue Kunden</b> kommen?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Bisher gewinnen Sie Ihre Anfragen hauptsächlich durch Empfehlungen oder den Kauf bei Immobilienportalen?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Doch Sie haben einfach keine Lust mehr, <u>hohe Provisionen</u> an Leadportale abgeben zu müssen, wenn überhaupt mal ein Auftrag zustande kommt?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Außerdem werden Ihre Anfragen an Ihre Mitbewerber verkauft, wodurch Sie ständig im Konkurrenzkampf mit anderen Maklern sind.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Und Empfehlungen nimmt man gerne mal mit, aber sie liefern nicht die notwendige Sicherheit.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Suchen Sie nach einem Weg, um <b>endlich planbar neue Aufträge</b> gewinnen, um ein solides Einkommen zu schaffen, das Ihnen die nötige Sicherheit bietet?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Dann brauchen Sie ein <u>System</u>, mit dem Sie <b>konstant</b> und <b>sicher</b> neue Anfragen gewinnen.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
