import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 0 20px 50px',
    widescreen: '0 0 20px 0',
    desktop: '0 0 20px 0',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '40px 0 100px',
        widescreen: '40px 0 100px',
        desktop: '40px 40px 80px',
        tablet: '60px 40px 40px',
        mobile: '20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    defaultCss: {
                      color: colors.title
                    },
                    margin: {
                      fullHd: '0 0 40px',
                      widescreen: '0 0 40px',
                      desktop: '0 0 40px',
                      tablet: '0 0 40px',
                      mobile: '0 0 10px'
                    },
                    text:
                      'Du solltest Deine Zeit sinnvoller nutzen, als Dir den Kopf um Marketing & Technik zu zerbrechen'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/neukundengewinnung/opening.jpg',
                    width: '100%',
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 4,
                    margin: {
                      fullHd: '90px 50px 0 50px',
                      widescreen: '0',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    defaultCss: {
                      textAlign: 'left',
                      color: colors.title
                    },
                    text:
                      'Du fragst Dich jeden Monat, wie Du an neue Kunden kommst?'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      fullHd: '20px 0 30px 50px',
                      widescreen: '20px 0 30px 0',
                      desktop: '20px 0 30px 0',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Du hast einfach <u>keine Lust</u> mehr auf Kaltakquise und Kontakte in den sozialen Medien anzuschreiben?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '… und willst endlich den nächsten Schritt gehen und <b>automatisiert Neukunden</b> gewinnen?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text: 'Glückwunsch! Du bist auf dem richtigen Weg, aber…'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b>Fakt ist:</b> Im Normalfall verbrennt man am Anfang enorm viel Werbebudget, bis man <u>endlich</u> herausfindet, worauf es im Online-Marketing <b>wirklich</b> ankommt.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
