import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const titleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 30px',
    tablet: '0 0 30px',
    mobile: '0 0 30px'
  }
}

const descriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemCancelIconConfig = {
  size: 4,
  icon: 'close',
  defaultCss: {
    color: '#fff',
    width: '30px',
    height: '30px',
    margin: '0 10px 0 0',
    padding: '3px 10px',
    borderRadius: '50%',
    background: '#bf4248'
  }
}

const itemDoneIconConfig = {
  icon: 'done',
  size: 4,
  defaultCss: {
    color: '#fff',
    width: '30px',
    height: '30px',
    margin: '0 10px 0 0',
    padding: '3px 10px',
    borderRadius: '50%',
    background: '#37761d'
  }
}

const cardConfig = {
  defaultCss: {
    backgroundColor: '#fff',
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
  },
  padding: {
    desktop: '60px 60px 40px',
    tablet: '40px',
    mobile: '20px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85)), url('/images/neukundengewinnung/oldwayNewway.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    defaultCss: {
                      color: '#fff'
                    },
                    text: 'Die „Komplett-Lösung“ für Deinen Erfolg'
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      color: '#fff',
                      textAlign: 'center'
                    },
                    margin: {
                      desktop: '0 240px 20px',
                      tablet: '0 0 20px',
                      mobile: '0 0 20px'
                    },
                    text:
                      'Wir wissen das Du schon genug zu tun hast. Deshalb übernehmen wir die <b>komplette Leadgewinnung</b> für Dich.'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 30px',
                      tablet: '30px auto 20px',
                      mobile: '30px auto 20px'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-tablet is-6-desktop is-flex',
              containers: [
                {
                  ...cardConfig,
                  containers: [
                    {
                      classNames: 'column is-full p-0',
                      containers: [
                        {
                          type: 'text',
                          config: {
                            text: '<u>Der alte Weg</u>',
                            ...titleConfig
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Hoher Zeitaufwand, um neue Interessenten zu gewinnen',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Du musst Deinen Kunden nachlaufen und bist in der schwächeren Position',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Geringe Bekanntheit und Unsichtbarkeit im Netz',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text: 'Keine Planbarkeit und wenig Sicherheit',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Interessenten verstehen Dein Angebot nicht und kaufen nicht, weil Sie den Mehrwert nicht sehen',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemCancelIconConfig
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-tablet is-6-desktop is-flex',
              containers: [
                {
                  ...cardConfig,
                  containers: [
                    {
                      classNames: 'column is-full p-0',
                      containers: [
                        {
                          type: 'text',
                          config: {
                            text: '<u>Die leeeds-Methode</u>',
                            ...titleConfig
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Vollautomatische Gewinnung von neuen Kunden rund um die Uhr',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text: 'Interessierte Kunden kommen auf Dich zu',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Du wirst zur bekannten Persönlichkeit, weil Du jederzeit sichtbar bist',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Kontinuierlich neue Anfragen sichern Dir Dein Einkommen jeden Monat',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        },
                        {
                          type: 'textWithIcon',
                          config: {
                            descriptionConfig: {
                              text:
                                'Wir bringen Dein Angebot auf den Punkt und heizen das Interesse Deiner Zielgruppe ordentlich an',
                              ...descriptionConfig
                            },
                            iconConfig: {
                              ...itemDoneIconConfig
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          type: 'cta',
          config: {
            dark: true,
            ...ctaButton,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
