// Defaults
import { Content, Calendly } from '@peakconcepts/landingpage-kit'
import defaultPages, { headerConfig } from './content/default/defaultPages'
import defaultSettings from './content/default/defaultSettings'
import seoHeaderData from './content/default/data/seoHeader'

// Pages
import neukundenPage from './content/pages/neukunden'
import immobilienmaklerPage from './content/pages/immobilienmakler'
import recruitingPage from './content/pages/recruiting'

// Google Ads Pages
import googleAdsImmobilienmaklerEffektveObjektakquise from './content/ads/immobilienmakler/effektive-objektakquise'
import googleAdsImmobilienmaklerMehrVerkaufsauftraege from './content/ads/immobilienmakler/mehr-verkaufsauftraege'
import googleAdsImmobilienmaklerMehrAlleinauftraege from './content/ads/immobilienmakler/mehr-alleinauftraege'
import googleAdsImmobilienmaklerGebietsschutz from './content/ads/immobilienmakler/gebietsschutz'
import googleAdsImmobilienmaklerExklusiveAnfragen from './content/ads/immobilienmakler/exklusive-anfragen'
import googleAdsImmobilienmaklerQualifizierteKontakte from './content/ads/immobilienmakler/qualifizierte-kontakte'

import generalData from './content/default/data/general'

const content: Content = {
  ...defaultSettings,
  pages: [
    {
      ...neukundenPage
    },
    {
      ...immobilienmaklerPage
    },
    {
      ...recruitingPage
    },
    {
      ...googleAdsImmobilienmaklerEffektveObjektakquise
    },
    {
      ...googleAdsImmobilienmaklerMehrVerkaufsauftraege
    },
    {
      ...googleAdsImmobilienmaklerMehrAlleinauftraege
    },
    {
      ...googleAdsImmobilienmaklerGebietsschutz
    },
    {
      ...googleAdsImmobilienmaklerExklusiveAnfragen
    },
    {
      ...googleAdsImmobilienmaklerQualifizierteKontakte
    },
    {
      path: '/recruiting/termin-buchen',
      headerEnabled: true,
      headerConfig: {
        ...headerConfig,
        containerConfig: {
          className: 'is-hidden-mobile',
          padding: {
            fullHd: '20px 0',
            widescreen: '20px 0',
            desktop: '20px 0',
            tablet: '20px 0',
            mobile: '10px 0'
          }
        }
      },
      seoHeaderConfig: seoHeaderData.default,
      component: Calendly,
      data: {
        ...generalData.calendly,
        url: 'https://calendly.com/leeeds/recruiting-potenzialanalyse'
      }
    },
    {
      path: '/neukundengewinnung/termin-buchen',
      headerEnabled: true,
      headerConfig: {
        ...headerConfig,
        containerConfig: {
          className: 'is-hidden-mobile',
          padding: {
            fullHd: '20px 0',
            widescreen: '20px 0',
            desktop: '20px 0',
            tablet: '20px 0',
            mobile: '10px 0'
          }
        }
      },
      seoHeaderConfig: seoHeaderData.default,
      component: Calendly,
      data: {
        ...generalData.calendly,
        url: 'https://calendly.com/leeeds/kostenloses-strategiegespraech'
      }
    },
    {
      path: '/immobilienmakler/termin-buchen',
      headerEnabled: true,
      headerConfig: {
        ...headerConfig,
        containerConfig: {
          className: 'is-hidden-mobile',
          padding: {
            fullHd: '20px 0',
            widescreen: '20px 0',
            desktop: '20px 0',
            tablet: '20px 0',
            mobile: '10px 0'
          }
        }
      },
      seoHeaderConfig: seoHeaderData.default,
      component: Calendly,
      data: {
        ...generalData.calendly,
        url:
          'https://calendly.com/leeeds/kostenloses-strategiegespraech-immobilienmakler'
      }
    },
    ...defaultPages
  ]
}
export default content
