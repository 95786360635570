import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '16px 16px 40px',
        mobile: '16px 16px 40px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              defaultCss: {
                background: colors.header
              },
              padding: {
                fullHd: '80px 0',
                widescreen: '80px 0',
                desktop: '80px 40px',
                tablet: '40px 20px',
                mobile: '40px 20px'
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    fontSize: {
                      desktop: '32px',
                      tablet: '24px',
                      mobile: '16px'
                    },
                    margin: {
                      desktop: '0',
                      tablet: '0',
                      mobile: '0 10px 20px'
                    },
                    defaultCss: {
                      color: '#fff'
                    },
                    text: 'Ihr Termin wurde erfolgreich gebucht ✅'
                  }
                },
                {
                  type: 'text',
                  config: {
                    fontSize: {
                      desktop: '20px',
                      tablet: '20px',
                      mobile: '16px'
                    },
                    margin: { desktop: '0', tablet: '0', mobile: '0' },
                    defaultCss: {
                      textAlign: 'center',
                      color: '#fff'
                    },
                    text:
                      'An dieser Stelle möchten wir uns für Ihr Vertrauen bedanken!'
                  }
                }
              ]
            },
            {
              classNames: 'column is-full',
              padding: {
                desktop: '80px 40px',
                tablet: '60px 30px',
                mobile: '40px 20px'
              },
              defaultCss: {
                boxShadow: '0 1px 5px rgb(0 0 0 / 20%)',
                media: [
                  {
                    breakpoint: 'tablet',
                    css: { boxShadow: 'none' }
                  }
                ]
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    text: 'Wie geht es jetzt weiter?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    fontSize: {
                      desktop: '20px',
                      tablet: '20px',
                      mobile: '18px'
                    },
                    margin: {
                      desktop: '0 200px 40px',
                      tablet: '0',
                      mobile: '0'
                    },
                    defaultCss: {
                      textAlign: 'center'
                    },
                    text:
                      'Sie bekommen die Möglichkeit zu <u>100 % kostenlos</u> all Ihre Fragen zu stellen und sich genau über die Zusammenarbeit mit uns zu informieren.<br/><br/> Um Ihre Fragen so gut wie möglich beantworten zu können, erhalten Sie zum vereinbarten Termin einen Anruf. <br/><br/> Basierend auf diesem Gespräch finden wir heraus, ob eine Zusammenarbeit <u>sinnvoll</u> ist.<br/><br/> In unserem Erstgespräch nehmen wir uns exklusiv Zeit für Sie und gehen <b>individuell</b> auf Ihre Situation ein.<br/><br/><br/><b>Wir freuen uns auf das Gespräch mit Ihnen.</b><br/><br/><br/>Beste Grüße <br/>Melik Su'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
