import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemImageContainerClassNames =
  'column is-12-mobile is-12-tablet is-4-desktop'
const itemTextContainerClassNames =
  'column is-12-mobile is-12-tablet is-8-desktop'

const itemContainerConfig = {
  margin: {
    desktop: '0 0 40px',
    tablet: '0 0 40px',
    mobile: '0 0 40px'
  }
}

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    backgroundColor: '#fff'
  },
  padding: {
    desktop: '20px',
    tablet: '20px',
    mobile: '20px'
  }
}

const itemTextContainerConfig = {
  defaultCss: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}

const itemTitleConfig = {
  size: 5,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const itemDescriptionConfig = {}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0 60px',
        widescreen: '80px 0 60px',
        desktop: '80px 40px 40px',
        tablet: '60px 40px 20px',
        mobile: '0 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline is-marginless',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      color: colors.title
                    },
                    margin: {
                      fullHd: '0',
                      widescreen: '0',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    text: 'Darüber hinaus profitieren Sie von:',
                    size: 2
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 40px',
                      tablet: '30px auto 40px',
                      mobile: '30px auto 40px'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/immobilienmakler/results/1.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Starkes Umsatzwachstum'
                          }
                        },
                        {
                          type: 'hr',
                          config: {
                            height: 2,
                            width: '80px',
                            defaultCss: {
                              color: 'primary'
                            },
                            margin: {
                              desktop: '20px 0',
                              tablet: '20px 0',
                              mobile: '20px 0'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Durch eine digitale Neukundengewinnungs-Maschine steigt Ihr Umsatz enorm. Während sie Ihnen einen endlosen Strom an neuen Kundenanfragen generiert, können Sie sich voll und ganz auf Ihre aktuellen Projekte konzentrieren. Sie erledigen doppelt so viele Aufträge in gleicher Zeit. '
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/immobilienmakler/results/2.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Bessere Kunden'
                          }
                        },
                        {
                          type: 'hr',
                          config: {
                            height: 2,
                            width: '80px',
                            defaultCss: {
                              color: 'primary'
                            },
                            margin: {
                              desktop: '20px 0',
                              tablet: '20px 0',
                              mobile: '20px 0'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Mit unserer Strategie drehen Sie den Spieß um, da neue Interessenten auf Sie zukommen und nicht Sie auf sie. Wir informieren Hausbesitzer vorab über alle Vorteile einer Zusammenarbeit mit Ihnen und erhöhen deren Interesse stark. Sie können sich aussuchen, mit wem Sie zusammenarbeiten und genau die Kunden auswählen, auf die Sie auch wirklich Lust haben.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/immobilienmakler/results/3.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Einfach und bequem Kosten sparen'
                          }
                        },
                        {
                          type: 'hr',
                          config: {
                            height: 2,
                            width: '80px',
                            defaultCss: {
                              color: 'primary'
                            },
                            margin: {
                              desktop: '20px 0',
                              tablet: '20px 0',
                              mobile: '20px 0'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Zeit = Geld. Eine effiziente Online-Kundengewinnung senkt Ihre Kosten stark. Sie müssen sich endlich nicht mehr selbst hinsetzen und Akquise machen. Denn als Immobilienmakler sollten Sie Ihre Zeit effizienter investieren, als neue Kunden zu finden.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
