import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 30px 20px 0',
    widescreen: '0 0 20px',
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '40px 0 80px',
        widescreen: '40px 0 80px',
        desktop: '40px 40px 80px',
        tablet: '30px 40px 60px',
        mobile: '20px 20px 40px'
      },
      containers: [
        {
          classNames: 'columns is-multiline is-vcentered',
          containers: [
            {
              classNames: 'column is-12-mobile is-4-tablet is-4-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/recruiting/wrongPlace.svg',
                    width: '100%'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-8-tablet is-8-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 3,
                    defaultCss: {
                      textAlign: 'left',
                      color: colors.title
                    },

                    text: 'Sie suchen die falschen Bewerber am falschen Ort'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      fullHd: '20px 0 30px 0',
                      widescreen: '20px 0 30px 0',
                      desktop: '20px 0 30px 0',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Entschuldigen Sie diese provokante Behauptung … aber wenn Sie wie 93 % der Arbeitgeber vorgehen, dann begehen Sie gleich 2 Fehler auf einmal:'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b>Das wahre Problem ist, dass Ihre Stelle auf Jobportalen nicht nur neben 1.000 anderen Anzeigen erscheint… sondern dass Sie sich mit allen anderen Arbeitgebern nur auf die 3 % der aktiven Jobsuchenden fokussieren.</b>'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Die Folge: Sie befinden sich mitten in einem harten Konkurrenzkampf mit Ihren Mitbewerbern. Die Wahrscheinlichkeit, dass sich ein passender Mitarbeiter genau auf Ihre Stellenanzeige bewirbt, ist sehr gering.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
