import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'

const itemImageConfig = {
  width: '100%'
}

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const itemCardTextBoxConfig = {
  defaultCss: {
    padding: '30px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(246, 246, 246, 0.8), rgba(246, 246, 246, 0.8)), url('/images/shapes/textur.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            defaultCss: {
              color: colors.title
            },
            margin: {
              desktop: '0 120px',
              tablet: '0',
              mobile: '0'
            },
            text:
              'Eine digitale Neukundengewinnungs-Maschine eröffnet Dir Chancen, wie es sie selten gibt'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto',
              mobile: '30px auto'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              defaultCss: {
                textAlign: 'center'
              },
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/neukundengewinnung/neue-kunden.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      ...itemCardTextBoxConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            size: 5,
                            text: 'Interessenten kommen<br/> auf Dich zu',
                            defaultCss: {
                              textAlign: 'center'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            defaultCss: {
                              textAlign: 'center'
                            },
                            text:
                              'Durch unsere Neukunden-Maschine brauchst Du keine Zeit mehr damit zu verschwenden, Kaltakquise zu betreiben und Menschen anzuschreiben, die keine Lust auf Dich haben, weil sich ab heute die Kontakte von selbst bei Dir melden.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              defaultCss: {
                textAlign: 'center'
              },
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/neukundengewinnung/interessenten.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      ...itemCardTextBoxConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            size: 5,
                            text:
                              'Nie wieder Angst haben, keine Kunden zu finden',
                            defaultCss: {
                              textAlign: 'center'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            defaultCss: {
                              textAlign: 'center'
                            },
                            text:
                              'Du brauchst Dir nie wieder Sorgen, um neue Kunden zu machen, denn Du erhältst jeden Monat konstant neue Anfragen von potenziellen Kunden, die eine Zusammenarbeit bei Dir anfragen. Neue Aufträge sind Dir jeden Monat sicher.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              defaultCss: {
                textAlign: 'center'
              },
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/neukundengewinnung/abschlussqouten.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      ...itemCardTextBoxConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            size: 5,
                            text: 'Höhere<br/> Abschlussquote',
                            defaultCss: {
                              textAlign: 'center'
                            }
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            defaultCss: {
                              textAlign: 'center'
                            },
                            text:
                              'Durch unsere Strategie machen wir Deine Zielgruppe heiß auf eine Zusammenarbeit mit Dir. Deine Gespräche machen mehr Spaß, denn Sie finden auf Augenhöhe statt, was sich in Deiner Abschlussquote deutlich bemerkbar macht.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
