import {
  Collapsible,
  CollapsibleInterface
} from '@peakconcepts/landingpage-kit'

const FAQs = (faqData: CollapsibleInterface) => {
  return <Collapsible {...faqData} />
}

export default FAQs
