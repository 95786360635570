import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemVideoContainerClassNames =
  'column is-12-mobile is-12-tablet is-4-desktop'
const itemTextContainerClassNames =
  'column is-12-mobile is-12-tablet is-8-desktop'

const itemContainerConfig = {
  margin: {
    desktop: '0 0 40px',
    tablet: '0 0 40px',
    mobile: '0 0 40px'
  }
}

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    backgroundColor: '#fff'
  },
  padding: {
    desktop: '20px',
    tablet: '20px',
    mobile: '20px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    fontStyle: 'italic'
  }
}

const itemNameConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '0 0 60px',
        widescreen: '0 0 60px',
        desktop: '0 40px 40px',
        tablet: '0 40px 20px',
        mobile: '0 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline is-marginless',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      color: colors.title
                    },
                    margin: {
                      fullHd: '0',
                      widescreen: '0',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    text: 'Kundenfeedback über die Zusammenarbeit',
                    size: 2
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 40px',
                      tablet: '30px auto 40px',
                      mobile: '30px auto 40px'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemVideoContainerClassNames,
                      containers: [
                        {
                          type: 'video',
                          config: {
                            url: 'https://vimeo.com/577056324'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„Leeeds.de ist nicht das erste Unternehmen mit dem wir zusammengearbeitet haben, um Anfragen von Eigentümern Online zu generieren, die Ihre Immobilie verkaufen möchten. Doch keiner konnte bisher sein Versprechen halten. Leeeds.de waren die ersten, die Ihr Versprechen gehalten haben. Das war absolut Spitze! Wir haben in der ersten Woche direkt Erfolge erzielt und gewinnbringende Umsätze erzielt haben.“'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemNameConfig,
                            text:
                              '⭐⭐⭐⭐⭐ &nbsp; Axel Steiner - Experte für Immobilienverkauf'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemVideoContainerClassNames,
                      containers: [
                        {
                          type: 'video',
                          config: {
                            url: 'https://vimeo.com/582065272'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„Wir haben bereits mit anderen Agenturen zusammengearbeitet und sind dort leider enttäuscht worden. Jetzt haben wir mit leeeds.de einen sehr guten und verlässlichen Partner gefunden, der uns zuverlässig neue Objektanfragen von Eigentümern, die Ihr Haus oder Ihre Wohnung verkaufen möchten bringt. Die Kommunikation funktioniert reibungslos und man wird in sämtlichen Bereichen erstklassig unterstützt. Wir können leeeds.de zu 100% weiterempfehlen!“'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemNameConfig,
                            text:
                              '⭐⭐⭐⭐⭐ &nbsp; Gregor Felix Grösser - Experte für Immobilienverkauf'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
