import { ContainerBox } from '@peakconcepts/landingpage-kit'

// IMMOBILIENMAKLER Data
import immobilienmaklerHeroData from '../../content/data/immobilienmakler/hero'
import immobilienmaklerOpeningData from '../../content/data/immobilienmakler/opening'
import immobilienmaklerGoodNewsData from '../../content/data/immobilienmakler/goodNews'
import immobilienmaklerAroundTheClockData from '../../content/data/immobilienmakler/aroundTheClock'
import immobilienmaklerBeforeYouStartData from '../../content/data/immobilienmakler/beforeYouStart'
import immobilienmaklerHowData from '../../content/data/immobilienmakler/how'
import immobilienmaklerSolutionData from '../../content/data/immobilienmakler/solution'
import immobilienmaklerAdvantagesData from '../../content/data/immobilienmakler/advantages'
import immobilienmaklerMoreAdvantagesData from '../../content/data/immobilienmakler/moreAdvantages'
import immobilienmaklerAboutData from '../../content/data/immobilienmakler/about'
import immobilienmaklerProcessData from '../../content/data/immobilienmakler/process'
import immobilienmaklerStatusQuoData from '../../content/data/immobilienmakler/statusQuo'
import immobilienmaklerNotTheSolutionData from '../../content/data/immobilienmakler/notTheSolution'
import immobilienmaklerOptionsData from '../../content/data/immobilienmakler/options'
import immobilienmaklerTerritorialProtectionData from '../../content/data/immobilienmakler/territorialProtection'
import immobilienmaklerFaqsData from '../../content/data/immobilienmakler/faqs'
import immobilienmaklerReviewData from '../../content/data/immobilienmakler/reviews'

// Shared
import knownFrom from '../../content/data/shared/knownfrom'

const data = [
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerHeroData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...knownFrom
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerOpeningData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerReviewData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerNotTheSolutionData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerGoodNewsData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerAroundTheClockData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerBeforeYouStartData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerHowData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerSolutionData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerAdvantagesData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerMoreAdvantagesData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerAboutData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerProcessData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerStatusQuoData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerOptionsData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerTerritorialProtectionData
    }
  },
  {
    component: ContainerBox,
    data: {
      ...immobilienmaklerFaqsData
    }
  }
]

export default data
