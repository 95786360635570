import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'
import FAQs from '../../../components/FAQs'

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 60px'
      },
      containers: [
        {
          type: 'text',
          config: {
            defaultCss: {
              color: colors.title
            },
            text: 'Häufig gestellte Fragen',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'component',
          component: FAQs,
          config: {
            items: [
              {
                headerConfig: {
                  textConfig: {
                    text:
                      'Funktioniert das für mein Produkt oder Dienstleistung?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Grundsätzlich funktioniert es für alle Branchen. Um herauszufinden, welche Hebel bei Dir sinnvoll sind, vereinbare ein kostenloses Gespräch mit uns.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Benötige ich technische Voraussetzungen?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Nein. Wir übernehmen im gesamten Prozess alle technischen Herausforderungen für Dich.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie lange dauert die Umsetzung?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Die Umsetzung dauert in der Regel 3 - 4 Wochen. Ab diesem Zeitpunkt wirst Du in der Regel bereits die ersten Kunden gewinnen.'
                  }
                }
              }
            ]
          }
        }
      ]
    }
  ]
}

export default data
