import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemTitleConfig = {
  size: 4,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '50px 0 10px',
    tablet: '50px 0 10px',
    mobile: '30px 0 10px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '40px 0',
        widescreen: '40px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',

                  config: {
                    size: 1,
                    defaultCss: {
                      textAlign: 'left'
                    },
                    text: 'Allgemeine Geschäftsbedingungen'
                  }
                },
                {
                  type: 'text',
                  config: {
                    size: 3,
                    defaultCss: {
                      textAlign: 'left'
                    },
                    text:
                      'Allgemeine Geschäftsbedingungen (AGB) leeeds - eingetragene Marke der Peakconcepts GmbH mit Sitz in Aalen, gegenüber Unternehmern und Kaufleuten.'
                  }
                },
                {
                  type: 'text',

                  config: {
                    ...itemTitleConfig,
                    text: '§ 1 Anwendungsbereich und Geltung'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Alle Lieferungen, Leistungen und Angebote von leeeds erfolgen ausschließlich auf der Grundlage dieser Allgemeinen Geschäftsbedingungen (AGB) von leeeds (nachfolgend auch „Auftragnehmer“ genannt). Die AGB sind Bestandteil aller Verträge, die leeeds mit seinen Vertragspartnern (nachfolgend auch  „Auftraggeber“ oder „Kunde“ genannt) über die von ihr angebotenen Lieferungen oder Leistungen schließt. Sie gelten ebenfalls für alle zukünftigen Lieferungen, Leistungen oder Angebote an den Auftraggeber, auch wenn diese nicht nochmals gesondert vereinbart werden.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Die Geschäftsbedingungen der Kunden oder Dritter finden keine Anwendung, auch wenn leeeds ihrer Geltung im Einzelfall nicht gesondert widerspricht.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 2 Leistungen und Bedingungen von leeeds'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) leeeds erbringt eine individuelle Beratungs- und/oder Agenturdienstleistungen, insbesondere im Bereich des Online-Marketings, Erstellung von Landingpages, Leadgenerierung, Anfragengenerierung, Mitarbeitergewinnung und der Optimierung digitaler Vertriebsprozesse. Soweit nicht ausdrücklich schriftlich anderweitig  vereinbart, schuldet leeeds dem Auftraggeber nicht die Erbringung eines Werks oder konkreten Erfolgs.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Der Auftraggeber ist für die Rechtskonformität etwaiger Marketingkampagnen, sowie Impressum, Datenschutzerklärung, Webseitenauftritt, etc. ausschließlich und selbst verantwortlich. leeeds übernimmt keine Haftung hierfür.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(3) Der Auftraggeber hat stets die ihm obliegenden Mitwirkungshandlungen vollständig und fristgemäß auf erstes Anfordern von leeeds zu erbringen. Unterlässt der Auftraggeber eine Mitwirkungshandlung und verhindert damit die Leistungserbringung in Teilen oder im Ganzen durch leeeds, bleibt der Vergütungsanspruch von leeeds hiervon unberührt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(4) leeeds weist darauf hin, dass Werbeplattformen wie Facebook, Instagram, YouTube, LinkedIn und Google jederzeit dazu berechtigt sind, Werbekampagnen ohne Nennung von Gründen jederzeit zu stoppen oder einzustellen. leeeds ist für dieses Vorgehen nicht verantwortlich.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(5) leeeds behält sich das Recht vor, dem Auftraggeber geschuldete Leistungen auch von Subunternehmern und Dritten erbringen zu lassen und benötigt hierzu keine gesonderte Genehmigung.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(6) In Bezug auf die von leeeds zu erbringenden Dienstleistungen gegenüber dem Auftraggeber steht leeeds in Bezug auf die Ausführung ein Leistungsbestimmungsrecht nach § 315 BGB zu.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(7) Die vereinbarte Vergütung von leeeds in Bezug auf deren Dienst- bzw. Beratungsleistungen enthalten kein Budget für etwaige Werbekampagnen des Auftraggebers. Dieses ist vom Auftraggebers separat zur Verfügung zu stellen und gegebenenfalls unmittelbar an den Werbeplattformbetreiber zu entrichten.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(8) leeeds garantiert keine konkrete Anzahl an Kundenanfragen und keine diesbezüglich bestimmte Qualität im Rahmen der durch die für den Auftraggebers geschalteten Werbekampagnen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(9) Landingpages und Domains (auch Subdomains), die im Rahmen der Zusammenarbeit mit dem Kunden von leeeds zur Verfügung gestellt werden, sind nach Beendigung der Zusammenarbeit an leeeds zu übergeben. Dem Auftraggeber steht kein Nutzungsrecht über die Dauer der Zusammenarbeit hinaus zu, ausgenommen es besteht eine schriftliche Zustimmung seitens des Auftragnehmers.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text:
                      '§ 3 Vertragsabschluss // Zustandekommen von Verträgen'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Der Vertragsschluss zwischen leeeds und dem Auftraggeber kann fernmündlich, schriftlich oder in Textform erfolgen. Gegebenenfalls auch durch eine Videokonferenz, wo der Auftragnehmer ausdrücklich zustimmt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Der Auftraggeber erhält bei fernmündlichen Vertragsschluss auf Wunsch von leeeds eine Bestätigung, ist jedoch für die Gültigkeit des Vertragsschluss nicht notwendig.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 4 Leistungen die eine Abnahme erfordern'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Die Leistungen von leeeds unterfallen grundsätzlich alle dem Dienstvertragsrecht. Sofern eine vereinbarte Leistung dem Werkvertragsrecht unterfällt und damit eine Abnahme notwendig ist, gelten nur in Bezug auf diese Leistungen die nachstehenden Absätze 2-10.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) leeeds kann vom Auftraggeber nach Abschluss der jeweiligen Teilleistung jeweils eine Abnahme der Teilleistung verlangen und nach Durchführung aller Anpassungsleistungen zusätzlich eine Gesamtabnahme aller Leistungen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(3) Die Abnahme der Leistungen setzt eine Funktionsprüfung durch den Auftraggeber voraus. Die Funktionsprüfung ist erfolgreich durchgeführt, wenn die Anpassungsleistungen die vereinbarten Anforderungen erfüllen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(4) Wird die Funktionsprüfung erfolgreich durchgeführt, ist die Abnahme unverzüglich zu erklären. leeeds kann den Auftraggeber mit Fristsetzung von einer Woche zur Teil- bzw. Gesamtabnahme auffordern. Sie gilt mit Ablauf der Frist als abgenommen, wenn der Auftraggeber gegenüber leeeds nicht schriftlich erklärt hat, welche Mängel noch zu beseitigen sind. Über etwaige Mängel wird ein Mängelprotokoll vom Auftraggeber angefertigt und leeeds überlassen. Das Übermittlungsrisiko liegt beim Auftraggeber.'
                  }
                },

                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(5) Soweit bei der Funktionsprüfung Mängel festgestellt werden, ist leeeds verpflichtet und berechtigt, diese weiter zu bearbeiten und zu beseitigen. Die Leistungen von leeeds zur Mängelbeseitigung sind dabei nach Zeitaufwand zu vergüten, sofern diese mehr als zwei Stunden überschreiten. Dies gilt auch für Leistungen zur Beseitigung von Mängeln, die erst nach Abnahme festgestellt werden. Die Berechnung erfolgt basierend auf einen branchenüblicher Stundensatz einer Unternehmensberatung.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(6) leeeds ist bei Vorliegen eines erheblichen Mangels berechtigt, zwei Mal binnen einer angemessenen und vom Auftraggeber zu setzenden Frist nachzubessern. Der insoweit entstehende Zeitaufwand ist vom Auftraggeber separat zu vergüten, Absatz (5) gilt entsprechend. Unerhebliche Mängel der (Teil-)Leistung stehen einer Abnahme nicht entgegen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(7) Ist zwischen den Parteien streitig, ob ein erheblicher oder ein unerheblicher Mangel eines Werkes vorliegt, ist darüber vor Betreiben eines Rechtsstreits ein von einer Industrie- und Handelskammer öffentlich bestellter Sachverständiger anzuhören. Der Auftraggeber ist für die angemessene Vergütung des Sachverständigen verpflichtet in Vorleistung zu gehen. Sollte der Sachverständige das Bestehen eines erheblichen Mangels am Werk feststellen, wird leeeds dem Auftraggeber die insoweit entstandenen Aufwendungen ersetzen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(8) Die abzunehmende (Teil-)Leistung von leeeds gilt auch dann als abgenommen, wenn der Auftraggeber sich auf Aufforderung von leeeds hin zur Abnahme der jeweiligen (Teil-)Leistung nicht binnen 10 Werktagen schriftlich erklärt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(9) Weitergehende Ansprüche des Auftraggebers, insbesondere auf Ersatz der erforderlichen Aufwendungen für die Beseitigung der Mängel, Schadenersatz und den Ersatz vergeblicher Aufwendungen bestehen nicht.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(10) Sofern die Mängel, die zur außerordentlichen Kündigung des Vertrages führen, nicht erhebliche Mängel im vorgenannten Sinn darstellen, hat der Auftraggeber auch keinen Anspruch auf Rückforderung von Teilen der Vergütung.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 5 Zahlungen, Preise, Bedingungen'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Die Preise, die von leeeds angegeben und mitgeteilt werden, sind verbindlich. Die mitgeteilten Preise verstehen sich jeweils netto zuzüglich gesetzlicher Umsatzsteuer.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Die Bezahlung der Leistungen von leeeds erfolgt sofort nach Rechnungserteilung. Die Vergütung der Dienste von leeeds sind grundsätzlich bei Abschluss des Vertrags fällig, es sei denn, das Angebot von leeeds beinhaltet eine andere Vereinbarung. Eine leeeds erteilte (SEPA-) Einzugsermächtigung gilt bis auf Widerruf auch für die weitere Geschäftsverbindung.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(3) Sofern der SEPA-Lastschrifteinzug vereinbart wird, hat der Auftraggeber leeeds nach Vertragsschluss ein schriftliches SEPA-Lastschriftmandat zu erteilen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(4) leeeds stellt dem Auftraggeber eine ordnungsgemäße Rechnung aus.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(5) Für den Fall, dass vereinbarte Lastschriften nicht vom Konto des Auftraggeber eingezogen werden können und eine Rückbuchung erfolgt, ist der Auftraggeber verpflichtet, den geschuldeten Betrag binnen 7 Werktagen nach Rückbuchung an leeeds zu überweisen und die durch die Rückbuchung veranlassten Kosten vollständig zu übernehmen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 6 Kündigung und Laufzeit'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Der Vertrag zwischen leeeds und dem Auftraggeber hat die die individuell zwischen den Parteien vereinbarte Mindestlaufzeit. Eine vorzeitige Kündigung ist ausgeschlossen. Wird der Vertrag zwischen leeeds und dem Auftraggeber nicht spätestens 4 Wochen vor Ablauf der Vertragslaufzeit gekündigt, verlängert er sich zu gleicher Laufzeit und zu gleichen Bedingungen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Etwaige darüber hinausgehenden freien Kündigungsrechte des Auftraggeber sind ausgeschlossen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(3) Kündigungen bedürfen zu ihrer Wirksamkeit der Schriftform.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(4) Das Recht zur außerordentlichen Kündigung bleibt unberührt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 7 Verzug // Außerordentliche Kündigung'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Die Fristen für die Leistungserbringung durch leeeds beginnen erst, wenn der vereinbarte Rechnungsbetrag bei leeeds eingegangen ist und vereinbarungsgemäß die für die Dienstleistungen notwendigen Daten beziehungsweise Informationen  bei leeeds vollständig vorliegen und / oder  die notwendigen Mitwirkungspflichten vom Auftraggeber vollständig erbracht sind.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Ist der Auftraggeber mit fälligen Zahlungen im Verzug, behält leeeds sich das Recht vor, weitere Leistungen bis zum Ausgleich des offenen Betrages nicht auszuführen. '
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(3) Ist der Auftraggeber im Fall einer vereinbarten Ratenzahlung mit mindestens zwei fälligen Zahlungen gegenüber leeeds in Verzug, ist leeeds dazu berechtigt, den Vertrag außerordentlich zu kündigen und die Leistungen einzustellen. leeeds wird die gesamte Vergütung, die bis zum nächsten ordentlichen Beendigungstermin fällig wird, als Schadensersatz geltend zu machen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 8 Erfüllung des Vertrags'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) leeeds wird die vereinbarten Dienstleistungen gemäß Angebot mit der erforderlichen Sorgfalt durchführen. leeeds ist berechtigt, sich dazu uneingeschränkt der Hilfe Dritter zu bedienen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Dem Auftraggeber ist bewusst, dass leeeds die Erbringung von Dienstleistungen und nicht die Herstellung eines Werks schuldet, es sei denn es besteht hierzu eine explizite schriftliche Vereinbarung . Auf Nachfrage des Auftraggeber wird leeeds innerhalb einer angemessenen Frist Auskunft über die im Rahmen des Vertrags erbrachten Dienste erteilen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(3) Falls leeeds die vereinbarten Dienstleistungen nicht erbringen kann, aus Gründen des Auftraggebers, bleibt der Vergütungsanspruch von leeeds unberührt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 9 Verhaltensweise'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Der Auftraggeber hat die üblichen Verhaltensweisen eines rechtlichen Kaufmanns gegenüber leeeds zu gewährleisten. leeeds behält sich das Recht vor, jede rechtswidrige und/oder unsachgemäße beziehungsweise sachgrundlose Äußerung über unser Unternehmen und unsere Dienstleistungen, sei es durch Kunden, Mitbewerber oder anderweitige Dritte, insbesondere unwahre Tatsachenbehauptungen und unangemessene Kritiken, zivilrechtlich zu verfolgen und darüber hinaus ohne Vorankündigung zur Strafanzeige zu bringen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 10 Schutzrechte Dritter'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Der Auftraggeber gewährleistet, dass leeeds überlassene Arbeitsmaterialien (z.B. Fotos, Texte, Video) frei von Rechten Dritter sind oder die für die Zwecke des Vertrages erforderlichen Genehmigungen vorliegen. Der Auftraggeber stellt leeeds insoweit von jeglicher Inanspruchnahme Dritter frei.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 11 Nutzung der Referenz'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Der Auftraggeber erteilt leeeds das Recht, die im Rahmen der vertraglichen erbrachten Leistungen in Teilen oder Gesamten als Referenz, Case-Study, Kundenergebnis etc. auf der Webseite, Social-Media-Kanälen oder sonstigen Publikationen zu verwenden und darzustellen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Der Auftraggeber sichert leeeds zu, auf Nachfrage ein Kundenfeedback in Text- oder Videoform bereit zu stellen.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 12 Nutzungsrechte'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Der Auftraggeber erhält für die Dauer der Vertragslaufzeit ein einfaches und nicht übertragbares Nutzungsrecht in Bezug auf die von leeeds erstellten und zur Verfügung gestellten Arbeits- und Leistungsergebnisse. Leistungs- und Arbeitsergebnisse im Sinne des zugrunde liegenden Vertrags sind alle Werk- bzw. Dienstleistungen oder Teile davon, die von leeeds für den Auftraggeber erstellt wurden (z B alle Informationen, Dokumente, Auswertungen, Videos, Fotos, im Rahmen der Auftragserfüllung erworbenes Knowhow, etc.). Solange Arbeitsergebnisse nicht fertig gestellt sind, gelten die entsprechenden Teilergebnisse als Arbeitsergebnisse im Sinne dieses Vertrages.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Absatz 1 gilt ausschließlich unter dem Vorbehalt, dass der Auftraggeber leeeds gemäß vertraglicher Vereinbarung zustehende Vergütung vollständig entrichtet hat.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(3) Ist Ratenzahlung vereinbart, geht das nach Absatz 1 benannte Nutzungsrecht vorbehaltlich anderslautender Individualvereinbarung erst mit vollständiger Zahlung der letzten Rate an leeeds über.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(4) Die Weitergabe der Arbeits- und Leistungsergebnisse an Dritte oder verbundene Unternehmen wird ausgeschlossen. Gleiches gilt für eine Bearbeitung nach § 23 UrhG.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 13 Widerrufsrecht'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Ein Widerrufsrecht für Unternehmer im Sinne des § 14 BGB besteht laut weder von Gesetzes wegen noch wird es von leeeds anderweitig eingeräumt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 14 Haftung'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) leeeds haftet auf Schadensersatz – gleich aus welchem Rechtsgrund - nur für Vorsatz und grobe Fahrlässigkeit. Bei einfacher Fahrlässigkeit haftet leeeds nur für Schäden aus der Verletzung einer wesentlichen Vertragspflicht (Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf). In diesem Fall ist die Haftung jedoch auf den Ersatz des vorhersehbaren, typischerweise eintretenden Schadens begrenzt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) In den Grenzen nach Absatz 1 haftet leeeds nicht für Daten- und Programmverluste. Die Haftung für Datenverlust wird der Höhe nach auf den typischen Wiederherstellungsaufwand beschränkt, der bei regelmäßiger und Gefahr entsprechender Anfertigung von Sicherungskopien eingetreten wäre. Die Haftung nach dem Produkthaftungsgesetz bleibt ebenso stets unberührt wie die für die Übernahme einer Garantie.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: '§ 15 Schlussbestimmungen'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(1) Abweichungen von diesen AGB sind nur wirksam, wenn diese schriftlich vereinbart wurden und von beiden Parteien unterschrieben sind oder nachweisliche anderweitig bestätigt worden sind. Im Einzelfall getroffene, individuelle Vereinbarungen mit dem Auftraggeber einschließlich Nebenabreden, Ergänzungen und Änderungen haben Vorrang vor diesen AGB. Für den Inhalt derartiger Vereinbarungen ist ein schriftlicher Vertrag bzw. die schriftliche Bestätigung von leeeds entscheidend.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '(2) Es gilt ausschließlich das Recht der Bundesrepublik Deutschland. Erfüllungsort ist der Sitz von leeeds.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    margin: {
                      desktop: '60px 0 0'
                    },
                    text:
                      'AGB Stand: 28.04.2021 © Alle Rechte vorbehalten. Vervielfältigung der AGBs sind ausdrücklich verboten.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
