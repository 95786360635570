import seoHeaderData from '../../content/default/data/seoHeader'
import immobilienmaklerSections from '../sections/immobilienmakler'

export const home = {
  path: '/immobilienmakler',
  headerEnabled: true,
  seoHeaderConfig: seoHeaderData.immobilienmakler,
  sections: immobilienmaklerSections
}

export default home
