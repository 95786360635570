import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 30px 20px 0',
    widescreen: '0 0 20px',
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '40px 0 80px',
        widescreen: '40px 0 80px',
        desktop: '40px 40px 80px',
        tablet: '30px 40px 60px',
        mobile: '20px 20px 40px'
      },
      containers: [
        {
          classNames: 'columns is-multiline is-vcentered',
          containers: [
            {
              classNames:
                'column is-12-mobile is-4-tablet is-4-desktop is-hidden-mobile',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/recruiting/mistake.svg',
                    width: '100%'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-8-tablet is-8-desktop ',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 3,
                    defaultCss: {
                      textAlign: 'left',
                      color: colors.title
                    },
                    text: 'Recruiting-Probleme, die jeder Arbeitgeber kennt'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      fullHd: '20px 0 30px 0',
                      widescreen: '20px 0 30px 0',
                      desktop: '20px 0 30px 0',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Kommt Ihnen das bekannt vor? Sie schalten Stellenanzeigen, inserieren Zeitungsannoncen und fragen sogar schon Bekannte nach einem heißen Tipp… doch die Suche nach einem passenden Mitarbeiter gestaltet sich schwierig?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b>Wie kann es sein, dass 48 % der Angestellten über einen Jobwechsel nachdenken, sich aber trotzdem kaum jemand auf Ihre Stellenanzeige meldet?</b>'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Könnte es sein, dass die Ursache nicht im angeblichen „Fachkräftemangel“, sondern in Ihrer Vorgehensweise liegt?'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
