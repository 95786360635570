import { HeaderInterface } from '@peakconcepts/landingpage-kit'
import generalData from './data/general'
import colors from './colors'

const header: HeaderInterface = {
  withContainer: true,
  containerConfig: {
    padding: {
      fullHd: '20px 0',
      widescreen: '20px 0',
      desktop: '20px 0',
      tablet: '20px 0',
      mobile: '10px 0'
    },
    defaultCss: {
      position: 'absolute',
      width: '100%',
      backgroundColor: 'unset'
    }
  },
  brandConfig: {
    className: 'ml-0',
    defaultCss: { justifyContent: 'center' },
    imageContainer: {
      className: 'p-0'
    },
    image: {
      src: '/images/logo-black.png',
      width: '120px',
      defaultCss: {
        maxHeight: 'unset !important'
      }
    }
  },
  burgerConfig: {
    hide: true
  }
}

const data = {
  config: {
    // gtmID: generalData.gtmID,
    theme: {
      primary: generalData.theme.primary,
      fonts: [
        {
          types: ['DEFAULT', 'HEADLINE'],
          fontFamily: 'Montserrat',
          fontWeight: 'wght@400;700;800'
        }
      ]
    }
  },
  footer: {
    copyright: 'Copyright © 2021. Alle Rechte reserviert.',
    dark: true,
    backgroundColor: colors.footer,
    image: {
      src: '/images/logo.png',
      width: '120px'
    },
    links: [
      {
        title: 'Impressum',
        to: '/impressum'
      },
      {
        title: 'Datenschutz',
        to: '/datenschutz'
      },
      {
        title: 'AGBs',
        to: '/agbs'
      }
    ],
    text:
      'Disclaimer: Die Ergebnisse variieren von Kunde zu Kunde. Welches Ergebnis Du mit unserer Zusammenarbeit erzielst, hängt von Deinem Produkt, Deinem Business-Model, Deinem Zeit und Kapitaleinsatz ab. Darum wäre es unseriös, Dir eine Gewinnerzielung zu Versprechen. Was wir Dir jedoch aus Erfahrung sagen können ist, dass unsere bisherigen Kunden alle qualifizierte Anfragen für Ihr Geschäft generieren konnten. <br/><br/>Hinweis: Das Angebot richtet sich in erster Linie an Geschäftskunden sowie an Gewerbetreibende & Unternehmen im Sinne des §14 BGB. This site is not a part of the Facebook TM website or Facebook TM Inc. Additionally, this site is NOT endorsed by FacebookTM in any way. FACEBOOK TM is a trademark of FACEBOOK TM, Inc.'
  },
  scrollToTop: {
    pixelFromEnd: 400
  },
  header,
  calendly: {
    pageSettings: {
      backgroundColor: generalData.calendly.pageSettings.backgroundColor,
      hideEventTypeDetails:
        generalData.calendly.pageSettings.hideEventTypeDetails,
      hideLandingPageDetails:
        generalData.calendly.pageSettings.hideLandingPageDetails,
      primaryColor: generalData.calendly.pageSettings.primaryColor,
      textColor: generalData.calendly.pageSettings.textColor
    },
    url: generalData.calendly.url,
    isPage: true
  }
}

export default data
