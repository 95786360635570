import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'
import FAQs from '../../../components/FAQs'

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 60px'
      },
      containers: [
        {
          type: 'text',
          config: {
            defaultCss: {
              color: colors.title
            },
            text: 'Häufig gestellte Fragen',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'component',
          component: FAQs,
          config: {
            items: [
              {
                headerConfig: {
                  textConfig: {
                    text:
                      'Warum hebe ich mich mit der „leeeds-Methode“ von der Konkurrenz ab?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Dank unserer Strategie entgehen Sie dem unmittelbaren Konkurrenzkampf zwischen allen anderen Maklern. Denn Sie gewinnen Ihre Objekt-Anfragen einfach selbst und müssen sich nicht ständig mit anderen Anbietern vergleichen. Nur Sie haben Zugriff auf Ihre Kontaktdaten.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Was bedeuted Gebietsschutz?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Es kann in einem Gebiet nur einen Platzhirsch geben. Mit unserer Methode sorgen wir dafür, dass Sie in Ihren Gebiet an Sichtbarkeit gewinnen und nicht länger im Konkurrenzkampf mit den anderen Maklern stehen. Um das zu gewährleisten, arbeiten wir nur mit einem Klienten pro Gebiet.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Benötige ich technische Voraussetzungen?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Nein. Wir übernehmen im gesamten Prozess alle technischen Herausforderungen für Sie.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie lange dauert die Umsetzung?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Die Umsetzung Ihrer eigenen Neukundengewinnungs-Maschine dauert in der Regel 3 - 4 Wochen. Ab diesem Zeitpunkt läuft Ihre Kampagne an und Sie werden Schritt für Schritt die ersten Anfragen gewinnen.'
                  }
                }
              }
            ]
          }
        }
      ]
    }
  ]
}

export default data
