import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'
import ctaButton from './ctaButton'

const data: ContainerBoxInterface = {
  containers: [
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px'
          },
          containers: [
            {
              type: 'text',
              config: {
                size: 2,
                defaultCss: {
                  color: colors.title
                },
                margin: {
                  fullHd: '0 120px',
                  widescreen: '0 120px',
                  desktop: '0',
                  tablet: '0',
                  mobile: '0'
                },
                text: 'Fehlende Mitarbeiter kosten Unternehmen viel Geld'
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 60px',
                  tablet: '30px auto 60px',
                  mobile: '30px auto 60px'
                }
              }
            },
            {
              type: 'text',
              config: {
                size: 5,
                defaultCss: {
                  textAlign: 'center'
                },
                margin: {
                  fullHd: '0 240px 20px',
                  widescreen: '0 240px 20px',
                  desktop: '0 0 20px',
                  tablet: '0 0 20px',
                  mobile: '0 0 20px'
                },
                text: '<b>Deshalb sollten Sie NICHTS dem Zufall überlassen.</b>'
              }
            },
            {
              type: 'text',
              config: {
                defaultCss: {
                  textAlign: 'center'
                },
                margin: {
                  fullHd: '0 240px 20px',
                  widescreen: '0 240px 20px',
                  desktop: '0 0 20px',
                  tablet: '0 0 20px',
                  mobile: '0 0 20px'
                },
                text:
                  'Wir nutzen eine erfolgserprobte Strategie, die <u>nachweislich</u> in der Praxis funktioniert. Die <b>leeeds-Methode</b> besteht aus einem durchdachten Schritt-für-Schritt-System, das es unmöglich macht, <u>nicht</u> die passenden Mitarbeiter zu finden.'
              }
            },
            {
              type: 'text',
              config: {
                size: 5,
                defaultCss: {
                  textAlign: 'center'
                },
                margin: {
                  fullHd: '0 240px 20px',
                  widescreen: '0 240px 20px',
                  desktop: '0 0 20px',
                  tablet: '0 0 20px',
                  mobile: '0 0 20px'
                },
                text:
                  'Alles, was Sie tun müssen:<br/> Unser Recruiting-System in Ihrem Unternehmen zu installieren!'
              }
            },
            {
              classNames: 'column is-12',
              containers: [
                {
                  type: 'cta',
                  config: {
                    dark: false,
                    margin: {
                      desktop: '10px 0 0',
                      tablet: '30px 0 0',
                      mobile: '10px 0 0'
                    },
                    ...ctaButton
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
