import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'
import ctaButton from './ctaButton'

const itemTitleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left',
    color: colors.title
  },
  margin: {
    desktop: '20px 0',
    tablet: '20px 0',
    mobile: '20px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'recruitingformel'
  },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(246, 246, 246, 0.8), rgba(246, 246, 246, 0.8)), url('/images/shapes/textur.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0',
            widescreen: '80px 0',
            desktop: '80px 40px',
            tablet: '60px 40px',
            mobile: '40px 20px'
          },
          containers: [
            {
              type: 'text',
              config: {
                size: 2,
                defaultCss: {
                  color: colors.title
                },
                fontSize: {
                  desktop: '48px',
                  tablet: '32px',
                  mobile: '24px'
                },
                margin: {
                  desktop: '0 0 5px',
                  tablet: '0 0 5px',
                  mobile: '0 0 5px'
                },
                text: 'Die Recruiting-Formel'
              }
            },
            {
              type: 'text',
              config: {
                defaultCss: {
                  textAlign: 'center'
                },
                fontSize: {
                  desktop: '24px',
                  tablet: '20px',
                  mobile: '18px'
                },
                margin: {
                  desktop: '0 0 10px',
                  tablet: '0 0 10px',
                  mobile: '0 0 10px'
                },
                text: 'In nur 3 Schritten zu Ihren Wunsch-Mitarbeitern'
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 50px',
                  tablet: '30px auto 50px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/recruiting/recruitingformel-1.svg',
                        width: '100%'
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
                  margin: {
                    fullHd: '90px 0 60px 0',
                    widescreen: '90px 0 60px 0',
                    desktop: '0',
                    tablet: '0',
                    mobile: '0'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        ...itemTitleConfig,
                        text: '<span>Schritt 1:</span> Der richtige Ort'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        ...itemDescriptionConfig,
                        text:
                          'Auf Jobportalen sprechen Sie nur die Bewerber an, die aktiv auf der Suche sind. Und hier liegt die Wurzel des Problems: Gute Mitarbeiter sind nicht auf der Jobsuche - sie sind auf der Arbeit!<br /><br />Trotzdem hat laut einer Studie jeder zweite Angestellte bereits innerlich gekündigt und würde sofort wechseln, wenn er ein passendes Jobangebot bekommen würde.<br /><br />Und damit ist nicht immer eine hohe Vergütung gemeint… oftmals sind die Arbeitszeiten, das Betriebsklima oder die Aufstiegschancen viel wichtiger für einen Mitarbeiter.'
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/recruiting/recruitingformel-2.svg',
                        width: '100%'
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
                  margin: {
                    fullHd: '90px 0 60px 0',
                    widescreen: '90px 0 60px 0',
                    desktop: '0',
                    tablet: '0',
                    mobile: '0'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        ...itemTitleConfig,
                        text: '<span>Schritt 2:</span> Das richtige Angebot'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        ...itemDescriptionConfig,
                        text:
                          'So gehen wir vor: Wir sprechen potenzielle Arbeitskräfte in den sozialen Medien direkt an. Menschen, die zur Qualifikation passen und bereit sind, den Arbeitgeber zu wechseln.<br /><br />Damit Sie auch die richtigen Mitarbeiter finden, gestalten wir Ihren Online-Auftritt so, dass sich Bewerber etwas unter ihrem neuen Arbeitsplatz vorstellen können.<br /><br />Danach erstellen wir für Sie ein Recruiting-System, dass es diesen Personen einfach macht, sich bei Ihnen zu bewerben.'
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/recruiting/recruitingformel-3.svg',
                        width: '100%'
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
                  margin: {
                    fullHd: '90px 0 60px 0',
                    widescreen: '90px 0 60px 0',
                    desktop: '0',
                    tablet: '0',
                    mobile: '0'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        ...itemTitleConfig,
                        text: '<span>Schritt 3:</span> Der richtige Mitarbeiter'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        ...itemDescriptionConfig,
                        text:
                          'Um eine Stelle langfristig zu besetzen, ist es wichtig, dass Sie einen Mitarbeiter finden, der zu der Stelle und Ihrer Firma passt. Nur so sind beide Parteien zufrieden und es entsteht eine langfristige Zusammenarbeit.<br /><br />Durch unsere Methode erschaffen wir Ihnen einen riesigen Pool an qualifizierten Bewerbern, aus denen Sie sich nur noch den passenden Kandidaten aussuchen müssen.<br /><br />Besetzen Sie jetzt vakante Stellen nachhaltig und effektiv, indem Sie einen Mitarbeiter finden, der wie die Faust aufs Auge zu Ihren Anforderungen passt.'
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12',
              containers: [
                {
                  type: 'cta',
                  config: {
                    dark: false,
                    margin: {
                      desktop: '10px 0 0',
                      tablet: '30px 0 0',
                      mobile: '10px 0 0'
                    },
                    ...ctaButton
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
