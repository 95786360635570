import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  },
  margin: {
    fullHd: '0 160px 20px',
    widescreen: '0 120px 20px',
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(246, 246, 246, 0.8), rgba(246, 246, 246, 0.8)), url('/images/shapes/textur.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0 180px',
            widescreen: '80px 0 180px',
            desktop: '80px 40px 180px',
            tablet: '60px 40px 200px',
            mobile: '40px 20px 200px'
          },
          containers: [
            {
              type: 'text',
              config: {
                defaultCss: {
                  color: colors.title
                },
                margin: {
                  fullHd: '0',
                  widescreen: '0',
                  desktop: '0',
                  tablet: '0 40px 20px',
                  mobile: '0 0 20px'
                },
                text: 'Immobilienmakler sind heutzutage sehr gefragt',
                size: 2
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 40px',
                  tablet: '30px auto 40px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              type: 'text',
              config: {
                text:
                  '<b>Insgeheim sucht jeder nach einem Experten, dem er vertrauen kann und der ihm hilft, sein Haus oder die Wohnung sicher & profitabel zu verkaufen.</b>',
                ...itemDescriptionConfig
              }
            },
            {
              type: 'text',
              config: {
                text:
                  'Das Geheimnis: Die besten Gespräche haben Sie, wenn jemand auf Sie zukommt und Sie um Rat fragt. Denn dann müssen Sie ihn nicht mehr überreden. Er kauft von selbst. Und genau DAS sollten Sie sich zunutze machen! Doch diese Menschen muss man erstmal finden…',
                ...itemDescriptionConfig
              }
            },
            {
              type: 'text',
              config: {
                text:
                  'Aber darum brauchen Sie sich keine Gedanken mehr zu machen, denn wir servieren Ihnen genau diese Kunden, wie auf dem Silbertablett. Sodass auch Ihnen jeden Monat 5-8 hochwertige Kundenanfragen zur Verfügung stehen.',
                ...itemDescriptionConfig
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
