import { ContentRenderer } from '@peakconcepts/landingpage-kit'
import ProvenExpert from './components/ProvenExpert'
import content from './content'
import './index.css'

function App() {
  return (
    <div className="App">
      <ContentRenderer content={content} />
      <ProvenExpert type="footer" />
    </div>
  )
}

export default App
