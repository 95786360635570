import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../default/colors'

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            defaultCss: {
              color: colors.title
            },
            text:
              'Willst Du Kundenanfragen wie am Fließband gewinnen und mehr Zeit für Dein Kerngeschäft haben?',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            defaultCss: {
              textAlign: 'center'
            },
            margin: {
              desktop: '0 120px',
              tablet: '0',
              mobile: '0'
            },
            text:
              'Dann klicke jetzt auf den Button und buche ein kostenloses Erstgespräch mit einem Experten aus dem Team! In einem 15 Minuten Telefongespräch finden wir gemeinsam heraus, ob und wie wir Dir helfen können.'
          }
        },
        {
          type: 'cta',
          config: {
            dark: false,
            ...ctaButton,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
