import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../../content/default/colors'

const itemImageConfig = {
  width: '100%'
}

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    backgroundColor: '#fff'
  },
  margin: {
    fullHd: '0',
    widescreen: '0',
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemCardTextBoxConfig = {
  defaultCss: {
    padding: '30px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '80px 0 0',
            widescreen: '80px 0 0',
            desktop: '80px 40px 0',
            tablet: '60px 40px 0',
            mobile: '40px 20px 0'
          },
          containers: [
            {
              type: 'text',
              config: {
                size: 2,
                defaultCss: {
                  color: colors.title
                },
                margin: {
                  desktop: '0 120px',
                  tablet: '0',
                  mobile: '0'
                },
                text:
                  'Eine digitale Neukundengewinnungs-Maschine eröffnet Ihnen Chancen, wie es sie selten gibt'
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 60px',
                  tablet: '30px auto 60px',
                  mobile: '30px auto 60px'
                }
              }
            },
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
                  defaultCss: {
                    textAlign: 'center'
                  },
                  containers: [
                    {
                      ...itemCardConfig,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src:
                              '/images/immobilienmakler/results/neue-kunden.jpg',
                            ...itemImageConfig
                          }
                        },
                        {
                          ...itemCardTextBoxConfig,
                          containers: [
                            {
                              type: 'text',
                              config: {
                                size: 5,
                                text: '100% Fokus auf<br/> Ihre Provision',
                                defaultCss: {
                                  textAlign: 'center'
                                }
                              }
                            },
                            {
                              type: 'text',
                              config: {
                                defaultCss: {
                                  textAlign: 'center'
                                },
                                text:
                                  'Durch einen automatisierten Vertriebsprozess brauchen Sie keine Zeit mehr damit zu verschwenden, neue Interessenten zu suchen, weil sich ab heute neue Kontakte von selbst bei Ihnen melden.'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
                  defaultCss: {
                    textAlign: 'center'
                  },
                  containers: [
                    {
                      ...itemCardConfig,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src:
                              '/images/immobilienmakler/results/interessenten.jpg',
                            ...itemImageConfig
                          }
                        },
                        {
                          ...itemCardTextBoxConfig,
                          containers: [
                            {
                              type: 'text',
                              config: {
                                size: 5,
                                text: 'Sicheres<br/> Einkommen',
                                defaultCss: {
                                  textAlign: 'center'
                                }
                              }
                            },
                            {
                              type: 'text',
                              config: {
                                defaultCss: {
                                  textAlign: 'center'
                                },
                                text:
                                  'Sie brauchen sich nie wieder Sorgen, um neue Aufträge zu machen, denn Sie erhalten jeden Monat konstant neue Anfragen von potenziellen Verkäufern, die Sie nur noch abtelefonieren müssen. Neue Aufträge sind Ihnen jeden Monat sicher.'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames:
                    'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
                  defaultCss: {
                    textAlign: 'center'
                  },
                  containers: [
                    {
                      ...itemCardConfig,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src:
                              '/images/immobilienmakler/results/abschlussqouten.jpg',
                            ...itemImageConfig
                          }
                        },
                        {
                          ...itemCardTextBoxConfig,
                          containers: [
                            {
                              type: 'text',
                              config: {
                                size: 5,
                                text: 'Höhere<br/> Abschlussquote',
                                defaultCss: {
                                  textAlign: 'center'
                                }
                              }
                            },
                            {
                              type: 'text',
                              config: {
                                defaultCss: {
                                  textAlign: 'center'
                                },
                                text:
                                  'Durch unsere Strategie werden nicht nur interessierte Kunden auf Sie zukommen, die Ihr Angebot in Anspruch nehmen wollen. Ihre Gespräche machen auch mehr Spaß, denn sie finden auf Augenhöhe statt, was sich in der Abschlussquote deutlich bemerkbar macht.'
                              }
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        classNames: 'is-hidden-widescreen',
                        margin: {
                          desktop: '0 auto 30px',
                          tablet: '0 auto 30px',
                          mobile: '0 auto 30px'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
                
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
