import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'

const itemImageContainerClassNames =
  'column is-12-mobile is-12-tablet is-4-desktop'
const itemTextContainerClassNames =
  'column is-12-mobile is-12-tablet is-8-desktop'

const itemContainerConfig = {
  margin: {
    desktop: '0 0 40px',
    tablet: '0 0 40px',
    mobile: '0 0 40px'
  }
}

const itemCardConfig = {
  defaultCss: {
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    backgroundColor: '#fff'
  },
  padding: {
    desktop: '20px',
    tablet: '20px',
    mobile: '20px'
  }
}

const itemTextContainerConfig = {
  defaultCss: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}

const itemTitleConfig = {
  size: 5,
  defaultCss: {
    textAlign: 'left'
  }
}

const itemDescriptionConfig = {}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0 60px',
        widescreen: '80px 0 60px',
        desktop: '80px 40px 40px',
        tablet: '60px 40px 20px',
        mobile: '0 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline is-marginless',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      color: colors.title
                    },
                    margin: {
                      fullHd: '0 160px',
                      widescreen: '0 160px',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    text:
                      'Deine Ergebnisse durch die persönliche Zusammenarbeit mit uns',
                    size: 2
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px auto 40px',
                      tablet: '30px auto 40px',
                      mobile: '30px auto 40px'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/neukundengewinnung/results/1.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text:
                              'Mehr Umsatz und Gewinn durch digitale Akquisewege'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Durch regelmäßige Kundenanfragen sicherst Du Dir ein stetiges Umsatzwachstum und stellst Dein Business auf ein solides Fundament. Du gewinnst also nicht nur mehr ideale Kunden, sondern steigerst auch Deine Umsätze und Gewinne.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/neukundengewinnung/results/2.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Systematisch mehr ideale Kunden gewinnen'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Ein automatisierter Verkaufsprozess mit System bringt Dir konstant neue Wunschkunden durch gezielte Online-Anzeigen. Zum richtigen Zeitpunkt erreicht Dein attraktives Angebot Deinen idealen Kunden, sodass dieser sich aktiv bei Dir meldet.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/neukundengewinnung/results/3.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Maximale Zeitersparnis'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Die Kaltakquise von Kunden erfordert viel Zeit und Mühe. Mit automatisierten Kundenanfragen sparst Du Dir Zeit und nimmst nur noch Kontakt mit Menschen auf, die bereits von Deinem Angebot überzeugt sind.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/neukundengewinnung/results/4.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text:
                              'Mehr Bekanntheit und Sichtbarkeit in Deiner Zielgruppe'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Zielgerichtete Online-Anzeigen erhöht die Sichtbarkeit in Deiner Zielgruppe und sorgt für einen hohen Bekanntheitsgrad, dadurch bekommst Du viele Anfragen, sodass Du Dir Deine Kunden aussuchen kannst.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/neukundengewinnung/results/5.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Einfach und bequem Kosten sparen'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Der automatisierte Akquiseprozess sorgt für sinkende Kosten und geringeren Personalbedarf im Vertrieb, wodurch Du bares Geld sparst, weil die Neukundenakquise nicht mehr aktiv betrieben werden muss.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-12-desktop',
              ...itemContainerConfig,
              containers: [
                {
                  classNames: 'columns is-multiline',
                  ...itemCardConfig,
                  containers: [
                    {
                      classNames: itemImageContainerClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/neukundengewinnung/results/6.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextContainerClassNames,
                      ...itemTextContainerConfig,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Planbares Wachstum'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Nicht messbare Ergebnisse im Vertrieb gehören mit unserer Methode der Vergangenheit an. Mit Hilfe von transparenten und messbaren Online-Anzeigen gewinnst Du planbar und kontinuierlich neue ideale Kunden. Dein Erfolg wird hierdurch planbar. Die wichtigsten Kennzahlen siehst Du jederzeit in einem Dashboard.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
