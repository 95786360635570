import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../default/colors'

const itemTitleConfig = {
  size: 2,
  defaultCss: {
    textAlign: 'left',
    color: colors.title,
    media: [
      {
        breakpoint: 'tablet',
        css: {
          textAlign: 'center'
        }
      }
    ]
  },
  margin: {
    desktop: '0 0 10px 0',
    tablet: '0 0 10px 0',
    mobile: '0 0 10px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0 40px',
        widescreen: '80px 0 40px',
        desktop: '80px 40px 40px',
        tablet: '60px 40px 20px',
        mobile: '40px 20px'
      },
      containers: [
        {
          defaultCss: {
            background: '#fff',
            boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
          },
          padding: {
            fullHd: '80px 80px 60px',
            widescreen: '80px 80px 60px',
            desktop: '80px 40px 60px',
            tablet: '60px 40px 40px',
            mobile: '40px 20px 20px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        defaultCss: {
                          media: [
                            {
                              breakpoint: 'tablet',
                              css: {
                                textAlign: 'center',
                                textDecoration: 'underline'
                              }
                            }
                          ]
                        },
                        margin: {
                          desktop: '0',
                          tablet: '0 60px 20px',
                          mobile: '0 0 20px'
                        },
                        text:
                          'Mehr Sicherheit, durch die Zusammenarbeit mit einem erfahrenen Partner'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        ...itemTitleConfig,
                        text: 'leeeds.de - eine Marke der Peakconcepts GmbH'
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-12-tablet is-5-desktop',
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/melik-su.jpg',
                        width: '100%'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        fontSize: {
                          desktop: '12px',
                          tablet: '12px',
                          mobile: '12px'
                        },
                        text:
                          '<b>Melik Su</b> - Geschäftsführer Peakconcepts GmbH '
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-12-tablet is-7-desktop',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text:
                          '<b>leeeds.de</b> steht für 7 Jahre Erfahrung in der digitalen Neukundengewinnung.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Du erhältst die geballte Power einer Marketing-Agentur, die bereits über 60 Kunden erfolgreich betreut.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Eine Zusammenarbeit mit uns ist kein Versuch.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '<b>Denn wir nutzen erfolgserprobte Systeme, die in der Praxis funktionieren…</b>',
                        ...itemDescriptionConfig
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-full pt-0',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text:
                          'Und wir wissen <b>ganz genau</b>, in welcher Situation Du Dich gerade befindest. Wir kennen den Markt und wissen, wie hart Kaltakquise ist und was Dir fehlt, um Dein Unternehmen auf das <b>nächste Level</b> zu bringen.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          'Wenn Du herausfinden möchtest, ob und wie wir Dir helfen können, dann klicke jetzt auf den Button und buche ein kostenloses persönliches Erstgespräch mit uns!',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        ...ctaButton,
                        margin: {
                          desktop: '50px 0 0',
                          tablet: '50px 0 0',
                          mobile: '30px 0 0'
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
