import seoHeaderData from '../../content/default/data/seoHeader'
import neukundengewinnungSections from '../sections/neukundengewinnung'

export const home = {
  path: '/neukundengewinnung',
  headerEnabled: true,
  seoHeaderConfig: seoHeaderData.neukundengewinnung,
  sections: neukundengewinnungSections
}

export default home
