import { DatenschutzInterface } from '@peakconcepts/landingpage-kit/lib'

const data: DatenschutzInterface = {
  sectionConfig: {
    padding: {
      desktop: '40px 0',
      tablet: '40px 0',
      mobile: '40px 0'
    }
  },
  company:
    'Peakconcepts GmbH<br/>Anton-Huber-Straße 20<br/>73430 Aalen<br/><br/>E-Mail: info@leeeds.de<br/>Telefon: 07361 / 99 78 500',
  provider: 'ALL-INKL',
  customCookieScriptTag: {
    id: 'CookieDeclaration',
    src:
      'https://consent.cookiebot.com/cfcbd96c-5a55-4d6c-8b50-135200af70bb/cd.js',
    type: 'text/javascript',
    async: true
  }
}

export default data
