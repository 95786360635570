import { HeaderInterface } from '@peakconcepts/landingpage-kit'
import colors from '../default/colors'
import seoHeaderData from '../default/data/seoHeader'
import recruitingSections from '../sections/recruiting'

const header: HeaderInterface = {
  withContainer: true,
  containerConfig: {
    padding: {
      fullHd: '20px 0',
      widescreen: '20px 0',
      desktop: '20px 0',
      tablet: '20px 0',
      mobile: '10px 0'
    },
    defaultCss: {
      position: 'absolute',
      width: '100%',
      backgroundColor: 'unset'
    }
  },
  brandConfig: {
    className: 'ml-0',
    defaultCss: { justifyContent: 'center' },
    imageContainer: {
      className: 'p-0'
    },
    image: {
      src: '/images/logo-black.png',
      width: '120px',
      defaultCss: {
        maxHeight: 'unset !important'
      }
    }
  },
  burgerConfig: {
    hide: true
  },
  menuEndConfig: {
    telButton: {
      text: '07361 / 99 78 500',
      className: 'pr-0',
      defaultCss: {
        background: 'none',
        border: 'none'
      },
      iconConfig: { defaultCss: { display: 'contents', fontSize: '30px' } }
    },
    items: [
      {
        text: 'Die Recruiting-Formel',
        scrollTo: 'recruitingformel',
        defaultCss: {
          color: 'black',
          background: 'unset !important'
        },
        underlineColor: colors.primary
      },
      {
        text: 'Ihre Vorteile',
        scrollTo: 'vorteile',
        defaultCss: {
          color: 'black',
          background: 'unset !important'
        },
        underlineColor: colors.primary
      },
      /*{
        text: 'Kundenergebnisse',
        scrollTo: 'kundenergebnisse',
        defaultCss: {
          color: 'black',
          background: 'unset !important'
        },
        underlineColor: colors.primary
      },*/
      {
        text: 'Über uns',
        scrollTo: 'about',
        defaultCss: {
          color: 'black',
          background: 'unset !important'
        },
        underlineColor: colors.primary
      }
    ]
  }
}

export const home = {
  path: '/',
  headerEnabled: true,
  headerConfig: header,
  seoHeaderConfig: seoHeaderData.recruiting,
  sections: recruitingSections
}

export default home
