import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../../default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85)), url('/images/neukundengewinnung/closing.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          defaultCss: {
            boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
            position: 'relative',
            background: 'rgba(255,255,255,.8)'
          },
          padding: {
            fullHd: '50px 100px',
            widescreen: '50px 100px',
            desktop: '50px',
            tablet: '40px',
            mobile: '20px'
          },
          containers: [
            {
              type: 'text',
              config: {
                text: 'So einfach wie heute wird es nie wieder sein…',
                size: 2,
                defaultCss: {
                  color: colors.title
                }
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 50px',
                  tablet: '30px auto 50px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  'Auch wenn es Online-Marketing schon seit einigen Jahren gibt, steckt es noch in den Kinderschuhen. Die Coaching- und Dienstleistungsbranche ist gerade am Anfang und dehnt sich erst jetzt enorm aus.'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  'Im Jahr 2018 gab es einen richtigen Boom im Online-Marketing durch die USA, welcher im Jahr 2020 durch die Corona-Krise enorm beschleunigt wurde.'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text: '<b>Alles wird digitaler.</b>'
              }
            },

            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  'Unternehmer, die sich früh genug im Internet platzieren, profitieren dank dem aktuellen Wandel enorm.'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  '<b>Und der Experten-Markt boomt! Immer mehr Menschen entwickeln ein Bewusstsein dafür, sich weiterzubilden und coachen zu lassen, um an ihre Ziele zu kommen.</b>'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text: 'Dabei ist es egal, welches Ziel.'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  'Das Internet wird immer mehr zur Gewohnheit und es wird immer selbstverständlicher, Experten zu beauftragen, um seine Probleme zu lösen. Und wenn es einen Zeitpunkt gibt, an dem man starten sollte, sich im Internet in einem gewissen Bereich zu positionieren und zu skalieren, dann <u>JETZT!</u>'
              }
            },
            {
              type: 'text',
              config: {
                ...itemDescriptionConfig,
                text:
                  'Denn jetzt werden die Karten verteilt und wer erst in paar Jahren beginnt, hat es wesentlich schwerer.'
              }
            },
            {
              type: 'cta',
              config: {
                dark: false,
                ...ctaButton,
                margin: {
                  desktop: '50px 0 0',
                  tablet: '50px 0 0',
                  mobile: '30px 0 0'
                }
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
