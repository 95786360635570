import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const itemDescriptionConfig = {
  fontSize: '18px',
  margin: {
    desktop: '0 0 30px',
    tablet: '0 0 30px',
    mobile: '0 0 30px'
  }
}

const itemCancelIconConfig = {
  size: 4,
  icon: 'close',
  defaultCss: {
    color: '#fff',
    width: '30px',
    height: '30px',
    margin: '0 10px 0 0',
    padding: '3px 10px',
    borderRadius: '50%',
    background: '#bf4248'
  }
}

const itemDoneIconConfig = {
  icon: 'done',
  size: 4,
  defaultCss: {
    color: '#fff',
    width: '30px',
    height: '30px',
    margin: '0 10px 0 0',
    padding: '3px 10px',
    borderRadius: '50%',
    background: '#37761d'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(21, 47, 70, 0.85), rgba(21, 47, 70, 0.85)), url('/images/immobilienmakler/notTheSolution.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'top center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 16px'
      },
      containers: [
        {
          type: 'text',
          config: {
            dark: true,
            margin: 0,
            size: 2,
            text: 'Warum Leads kaufen <u>NICHT</u> die Lösung ist',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 40px',
              tablet: '30px auto 40px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            dark: true,
            text:
              'Viele Immobilienmakler setzen große Hoffnung in das Kaufen von Adressdaten.',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          type: 'text',
          config: {
            dark: true,
            text: '<u>Das große Problem bei Leadportalen ist:</u>',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          type: 'text',
          config: {
            dark: true,
            margin: {
              desktop: '0 0 40px',
              tablet: '0 0 40px',
              mobile: '0 0 40px'
            },
            text:
              'Die Leads werden auch an andere Makler verkauft. In der Regel sind Sie der dritte oder vierte Anrufer und Ihre Kunden schließen nie ab, denn sie wollen immer noch weitere Angebote vergleichen!',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  defaultCss: {
                    backgroundColor: 'white',
                    height: '100%'
                  },
                  padding: {
                    desktop: '30px',
                    tablet: '30px',
                    mobile: '30px 30px 10px 30px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text: 'Einkauf bei Leadportalen',
                        margin: {
                          desktop: '0 0 50px',
                          tablet: '0 0 50px',
                          mobile: '0 0 40px'
                        },
                        defaultCss: {
                          textAlign: 'left'
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Abhängigkeit von Leadportalen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemCancelIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Teure und unbrauchbare Leads',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemCancelIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Sie müssen Ihren Kunden nachlaufen und Sie sind in der schwächeren Position',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemCancelIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Leadportale verkaufen die Leads an Ihre Mitbewerber weiter',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemCancelIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Ihre Konkurrenz nutzt den digitalen Wandel und hängt Sie ab',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemCancelIconConfig
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  defaultCss: {
                    backgroundColor: 'white',
                    height: '100%'
                  },
                  padding: {
                    desktop: '30px',
                    tablet: '30px',
                    mobile: '30px 30px 10px 30px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text: 'leeeds - Methode',
                        margin: {
                          desktop: '0 0 50px',
                          tablet: '0 0 50px',
                          mobile: '0 0 40px'
                        },
                        defaultCss: {
                          textAlign: 'left'
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Unabhängigkeit durch selbst generierte Anfragen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemDoneIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Interessierte und kaufbereite Anfragen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemDoneIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Neue Kunden melden sich aktiv bei Ihnen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemDoneIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Ihre Leads fordern eine Zusammenarbeit exklusiv bei Ihnen an',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemDoneIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Sie dominieren Ihr Gebiet und sind Ihrer Konkurrenz einen Schritt voraus',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemDoneIconConfig
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-desktop pb-0',
              containers: [
                {
                  type: 'cta',
                  config: {
                    dark: true,
                    margin: {
                      desktop: '10px 0 0',
                      tablet: '10px 0 0',
                      mobile: '10px 0 0'
                    },
                    ...ctaButton
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
