const general = {
  title: 'leeeds.de » Mehr Kunden. Mehr Aufträge. Mehr Umsatz.',
  description:
    'Erhalte täglich automatisiert kaufkräftige Kundenanfragen und steigere dadurch Deinen Umsatz massiv mit der Leeeds-Methode.',
  author: 'leeeds',
  publisher: 'leeeds',
  revisitAfter: '7 days',
  type: 'website',
  image: 'images/social/basic.jpg',
  site: '',
  twitterCard: 'summary_large_image',
  creator: 'leeeds'
}

const data = {
  default: {
    ...general
  },
  recruiting: {
    ...general,
    title: 'leeeds.de » Mehr Bewerbungen. Mehr Mitarbeiter. Mehr Bekanntheit.',
    description:
      'Mitarbeitergewinnung leicht gemacht. Erfolgreiches Recruiting. Finden Sie jetzt die passenden Mitarbeiter.'
  },
  neukundengewinnung: {
    ...general
  },
  immobilienmakler: {
    ...general,
    title: 'leeeds.de » Exklusive Anfragen von Haus- und Wohnungsbesitzer',
    image: 'images/social/immobilienmakler.jpg',
    description:
      'Als Immobilienmakler jeden Monat 5-8 exklusive Anfragen von Hausbesitzern erhalten, die ihr Objekt verkaufen wollen'
  },
  imprint: {
    ...general,
    title: 'Impressum » leeeds.de'
  },
  policy: {
    ...general,
    title: 'Datenschutz » leeeds.de'
  },
  terms: {
    ...general,
    title: 'AGBs » leeeds.de'
  },
  thankYou: {
    ...general,
    title: 'Danke » leeeds.de'
  }
}

export default data
