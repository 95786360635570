import {
  ContainerBoxInterface,
  AvailableContainerBoxVariants
} from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 30px 20px 0',
    widescreen: '0 0 20px',
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '100px 0',
        widescreen: '100px 0',
        desktop: '120px 40px 80px',
        tablet: '80px 40px 40px',
        mobile: '20px 20px 0'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          variant: AvailableContainerBoxVariants.ReverseTablet,
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 3,
                    margin: {
                      fullHd: '50px 160px 0 0',
                      widescreen: '0',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    defaultCss: {
                      textAlign: 'left',
                      color: colors.title
                    },

                    text: 'Mache nicht den gleichen Fehler wie die anderen'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      fullHd: '20px 0 30px 0',
                      widescreen: '20px 0 30px 0',
                      desktop: '20px 0 30px 0',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Viele Coaches, Experten, Trainer und Dienstleister möchten Geld sparen und versuchen sich Online-Marketing selbst beizubringen. Sie verzweifeln an der Erstellung von Webseiten, Werbetexten und dem Algorithmus der sozialen Medien.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Dabei begehen sie nicht nur den Fehler, sich nicht mehr auf die Dinge zu konzentrieren, die sie wirklich gut können (Menschen helfen), sie ignorieren auch die Tatsache, dass Online-Marketing sehr komplex und vielseitig ist.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Online-Marketing benötigt Zeit und vor allem Erfahrung, bis man optimale Ergebnisse erzielt!'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/neukundengewinnung/mistake.jpg',
                    width: '100%',
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
