import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../../content/default/colors'

const itemNumberBoxClassNames =
  'column is-2-mobile is-1-tablet is-1-desktop pt-0 pb-0'
const itemTextBoxClassNames =
  'column is-10-mobile is-11-tablet is-11-desktop pl-0 pb-5 pt-0'

const itemDotsContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center'
  }
}

const itemTextBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

const itemDescriptionConfig = {
  defaultCss: {}
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0 60px',
        widescreen: '80px 0 60px',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            defaultCss: {
              color: colors.title
            },
            margin: {
              fullHd: '0 220px 0',
              widescreen: '0',
              desktop: '0',
              tablet: '0',
              mobile: '0'
            },
            text:
              'Unsere Vorgehensweise ist effektiv, denn wir wissen, worauf es wirklich ankommt'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          padding: {
            fullHd: '30px 0 0 0',
            widescreen: '30px 0 0 0',
            desktop: '30px 0 0 0',
            tablet: '30px 0 0 0',
            mobile: '30px 0 0 0'
          },
          classNames: 'columns is-multiline is-mobile',
          containers: [
            {
              defaultCss: {
                display: 'flex'
              },
              containers: [
                {
                  classNames: itemNumberBoxClassNames,
                  ...itemDotsContainer,
                  containers: [
                    {
                      type: 'dots',
                      config: {
                        color: 'rgb(255,170,0)',
                        dotConfig: {
                          dotSize: 2
                        }
                      }
                    }
                  ]
                },
                {
                  classNames: itemTextBoxClassNames,
                  ...itemTextBoxContainer,
                  containers: [
                    {
                      type: 'text',
                      config: {
                        ...itemDescriptionConfig,
                        text:
                          'Mit der „leeeds – Methode“ sprechen wir <u>potenzielle Kunden</u> in den sozialen Medien direkt an. Menschen, die ihr Haus oder ihre Wohnung verkaufen möchten…'
                      }
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '… diesen Personen bieten wir ein attraktives Angebot, dem sie <u>nicht widerstehen können</u>, sodass sie sich dann von selbst <b>bei Ihnen melden</b>, um sich zu informieren…'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            showLines: false,
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '… so schaffen wir Ihnen laufend einen <b>riesigen Pool</b> an brandneuen Interessenten, die ihre Immobilie verkaufen möchten. Diese Menschen fragen eine Zusammenarbeit exklusiv bei Ihnen an. Das heißt: <b>Kein anderer Makler hat Zugriff auf diese Adressdaten!</b>'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
