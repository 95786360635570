import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../default/colors'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    fullHd: '0 0 20px 50px',
    widescreen: '0 0 20px 0',
    desktop: '0 0 20px 0',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const data: ContainerBoxInterface = {
  lazyConfig: { once: true, offset: 200 },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '40px 0',
        widescreen: '40px 0',
        desktop: '0 40px 0',
        tablet: '0 40px 0',
        mobile: '20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/neukundengewinnung/gamechanger.jpg',
                    width: '100%',
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)'
                    }
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-12-tablet is-6-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 3,
                    margin: {
                      fullHd: '80px 50px 0 50px',
                      widescreen: '0',
                      desktop: '0',
                      tablet: '0',
                      mobile: '0'
                    },
                    defaultCss: {
                      textAlign: 'left',
                      color: colors.title
                    },

                    text: 'Der Gamechanger #1'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      fullHd: '20px 0 30px 50px',
                      widescreen: '20px 0 30px 0',
                      desktop: '20px 0 30px 0',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<u>Du kennst es bestimmt</u>: Die besten Gespräche hast Du, wenn jemand auf Dich zu kommt und Dich um Rat fragt. Denn dann musst Du ihn nicht mehr überreden… <br/><br/>… er kauft von selbst! '
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b>Und dieses Prinzip solltest Du für Dich nutzen!</b>'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text: 'Doch diese Menschen muss man erst mal finden.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Aber darum brauchst Du Dir keine Gedanken mehr zu machen… denn <u>wir servieren Dir genau diese Kunden wie auf dem Silbertablett</u>.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
