import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 20px 0 10px;
  display: flex;
  background: #152f46;
  width: 100%;
  justify-content: center;
`
const Expert = styled.div`
  span {
    line-height: 1 !important;
  }
  .pe_u,
  .pe_u span {
    color: white !important;
    font-size: 14px !important;
  }
  #pe_name {
    max-width: 150px;
  }
`
const Sidebar = styled.div`
  @media (max-width: 1400px) {
    .ProvenExpert_widget_container {
      display: none !important;
    }
  }
  a {
    text-decoration: none;
    z-index: 9999;
    position: absolute;
    float: left;
    line-height: 0;
    right: 10px;
    top: 100px;
  }
  img {
    border: 0;
  }
`
const ProvenExpert = ({
  type = 'floating'
}: {
  type?: 'footer' | 'floating'
}) => {
  if (type === 'floating')
    return (
      <Sidebar>
        <a
          className="ProvenExpert_widget_container"
          href="https://www.provenexpert.com/peakconcepts-gmbh/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
          title="Kundenbewertungen &amp; Erfahrungen zu PEAKCONCEPTS GmbH. Mehr Infos anzeigen."
          target="_blank"
          rel="noreferrer">
          <img
            src="https://images.provenexpert.com/da/96/0a7a5b2fd3e0dbc9f691ee1a1207/widget_square_180_de_0.png"
            alt="Erfahrungen &amp; Bewertungen zu PEAKCONCEPTS GmbH"
            width="150"
            height="150"
          />
        </a>
      </Sidebar>
    )
  return (
    <Container>
      <Expert className="pe-richsnippets"></Expert>
    </Container>
  )
}

export default ProvenExpert
