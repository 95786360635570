import seoHeaderData from '../../default/data/seoHeader'
import immobilienmaklerSections from '../../sections/immobilienmakler'

export const home = {
  path: '/immobilienmakler/gebietsschutz',
  headerEnabled: true,
  seoHeaderConfig: seoHeaderData.immobilienmakler,
  sections: immobilienmaklerSections
}

export default home
