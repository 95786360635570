import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const badgeItemcontainerClassNames =
  'column is-12-mobile is-4-tablet is-4-desktop is-4-widescreen'

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: '#fff'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '40px 0',
        widescreen: '40px 0',
        desktop: '80px 40px 40px',
        tablet: '60px 40px 20px',
        mobile: '40px 20px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-2-desktop is-2-widescreen',
              containers: [
                {
                  type: 'text',
                  config: {
                    fontSize: {
                      fullHd: '18px',
                      widescreen: '18px',
                      desktop: '14px',
                      tablet: '18px',
                      mobile: '18px'
                    },
                    margin: {
                      fullHd: '10px 0 0',
                      widescreen: '5px 0 0',
                      desktop: '5px 0 0',
                      tablet: '0',
                      mobile: '0'
                    },
                    defaultCss: {
                      fontWeight: 'bold',
                      media: [
                        {
                          breakpoint: 'tablet',
                          css: { textAlign: 'center' }
                        }
                      ]
                    },
                    text: 'Bekannt aus:'
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-10-desktop is-10-widescreen',
              containers: [
                {
                  classNames: 'columns is-multiline',
                  containers: [
                    {
                      classNames: badgeItemcontainerClassNames,
                      defaultCss: {
                        textAlign: 'center'
                      },
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/badges/gewinnermagazin.png',
                            width: {
                              fullHd: '100%',
                              widescreen: '100%',
                              desktop: '100%',
                              tablet: '100%',
                              mobile: '80%'
                            },
                            margin: {
                              fullHd: '0 auto',
                              widescreen: '0 auto',
                              desktop: '0 auto',
                              tablet: '0 auto',
                              mobile: '0 auto'
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: badgeItemcontainerClassNames,
                      defaultCss: {
                        textAlign: 'center'
                      },
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/badges/unternehmerjournal.png',
                            width: {
                              fullHd: '100%',
                              widescreen: '100%',
                              desktop: '100%',
                              tablet: '100%',
                              mobile: '80%'
                            },
                            margin: {
                              fullHd: '0 auto',
                              widescreen: '0 auto',
                              desktop: '0 auto',
                              tablet: '0 auto',
                              mobile: '0 auto'
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: badgeItemcontainerClassNames,
                      defaultCss: {
                        textAlign: 'center'
                      },
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/badges/om-magazin.png',
                            width: {
                              fullHd: '100%',
                              widescreen: '100%',
                              desktop: '100%',
                              tablet: '100%',
                              mobile: '80%'
                            },
                            margin: {
                              fullHd: '0 auto',
                              widescreen: '0 auto',
                              desktop: '0 auto',
                              tablet: '0 auto',
                              mobile: '0 auto'
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
